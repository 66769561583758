// MainPage.tsx

import React from "react";
import HeroSection from "./HeroSection";
import PartnersSection from "./PartnerSection";
import AboutSection from "./About";
import FeaturesSection from "./Features";
import HowItWorks from "./HowItWorks";
import DownloadAppSection from "./DownloadApp";
import FAQSection from "./FAQ/Faq";
import ContactUsSection from "./ContactUS/ContactUs";
import { Container, IconButton, useMediaQuery, useTheme } from "@mui/material";
import NavBar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";

import { ReactComponent as NewWhatsappIcon } from "../../assets/WhatsAppSCROLSVG.svg";
import { useTranslation } from "react-i18next";
import { useLanguageTransition } from "../../LanguageTransitionContext";
import CustomHelmet from "../../components/Helmet/Helmet";

const Home: React.FC = () => {
  const { t, i18n } = useTranslation();
  const isRtl = i18n.language === "ar"; // Check if language is Arabic

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { isTransitioning } = useLanguageTransition();
  const heroSectionRef = React.useRef(null); // Ref for the hero section
  const isIpad = useMediaQuery("(min-width:768px) and (max-width:1200px)");

  React.useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <>
      <CustomHelmet title={t("sak") + " - " + t("home")} />
      <Container maxWidth="lg" sx={{ margin: "30px 0" }}>
        <NavBar heroSectionRef={heroSectionRef} />
      </Container>

      <Container maxWidth="lg">
        <IconButton
          color="inherit"
          href="https://wa.me/+966920033232"
          target="_blank"
        >
          <NewWhatsappIcon
            style={{
              position: "fixed",
              top: "85%", // Adjust for mobile
              [isRtl ? "left" : "right"]: isMobile
                ? "8%"
                : isIpad
                ? "6%"
                : "10%", // Position based on language direction
              zIndex: 1000,
              width: isMobile ? "48px" : "64px", // Smaller on mobile
              height: isMobile ? "48px" : "64px", // Smaller on mobile
              transition: "opacity 0.5s ease, transform 0.5s ease",
              opacity: isTransitioning ? 0 : 1,
              transform: isTransitioning
                ? `translateX(${isTransitioning ? "-30px" : "30px"})`
                : "translateX(0)",
            }}
          />
        </IconButton>
      </Container>

      <HeroSection id="home" homeRef={heroSectionRef} />
      <PartnersSection id="partners" />
      <AboutSection id="about sak" />
      <FeaturesSection id="features" />
      <HowItWorks id="how it works?" />
      <DownloadAppSection id="download app" />
      <FAQSection id="faq" />
      <ContactUsSection id="contact us" />
      <Footer />
    </>
  );
};

export default Home;
