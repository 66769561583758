import React from "react";
import {
  DialogContent,
  DialogTitle,
  Typography,
  Box,
  useTheme,
  Drawer,
} from "@mui/material";
import { textStyle, textStyleRegular } from "../../shared/styles";
import sellOnMap3X from "../../assets/SellOnMap3xLast.png";
import { ReactComponent as WafiIcon } from "../../assets/wafiSVG.svg";

import { useTranslation } from "react-i18next";
import { StyledButton } from "./StyledButton";

const SellOnMapBottomSheet = ({ open, onClose }: any) => {
  const theme = useTheme();
  const { t, i18n } = useTranslation();
  const isRtl = i18n.language === "ar";

  React.useEffect(() => {
    const img = new Image();
    img.src = sellOnMap3X;
  }, []);

  return (
    <Drawer
      anchor="bottom"
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          boxShadow: "0px 4px 10px rgba(200, 200, 200, 0.3)",
          borderRadius: "24px 24px 0 0", // Rounded corners on the top side
          padding: theme.spacing(2),
          minHeight: "30vh", // Adjust the height of the bottom sheet
          maxHeight: "90vh",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          overflow: "hidden",
          maxWidth: "100%",
          padding: " 0 20px",
          backgroundColor: "#F7F7FF",
          borderRadius: "20px",
        }}
      >
        {open && (
          <img
            src={sellOnMap3X}
            alt="Sell on Map"
            style={{ maxWidth: "100%", height: "100%" }}
          />
        )}
      </Box>
      <DialogTitle
        sx={{
          ...textStyle,
          fontSize: "16px",
          color: "#1C1B13",
          textAlign: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center", // Vertically center the items
            justifyContent: "space-between", // Space between text and image
            width: "100%", // Use full width of the container
            padding: "20px", // Padding around the container
            flexDirection: isRtl ? "row-reverse" : "row", // Reverse the direction for RTL languages
          }}
        >
          <Typography
            className={isRtl ? "rtl-text" : ""}
            sx={{
              ...textStyle,
              color: "#1C1B13",
              textAlign: "center",
              fontWeight: 700,
              fontSize: "18px",
              flexGrow: 1, // Allow the text to take available space
              margin: "0 10px",
            }}
          >
            {t("sellOnMapTitle")}
          </Typography>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              overflow: "hidden",
              maxWidth: "50%", // Adjust the width of the image container
            }}
          >
            <WafiIcon />
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Typography
          className={isRtl ? "rtl-text" : ""}
          gutterBottom
          sx={{
            ...textStyleRegular,
            color: "#1C1B13",
            textAlign: "center",
          }}
        >
          {t("sellOnMapDesc")}
        </Typography>
        <StyledButton onClick={onClose}>{t("Close")}</StyledButton>{" "}
      </DialogContent>
    </Drawer>
  );
};

export default SellOnMapBottomSheet;
