import React, { useState } from "react";
import InputOther from "../actions/input-other";
import { InputPhonecountry } from "../actions/input-phone";
import { SendData } from "../api/index.js";
import { useParams } from "react-router-dom";
import validator from "validator";

type Props = {};

const Form = (props: Props) => {
  const { id } = useParams();
  const [inputValues, setInputValues] = useState({
    username: "",
    email: "",
  });
  const [phone, setPhone] = useState("");

  const [ErrorInput, setErrorsInput] = useState({
    username: "",
    phone: "",
  });
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(true);

  const handleOnChange = (event: any) => {
    const { name, value } = event.target;
    const re = /^[0-9\b]+$/;
    if (name === "username") {
      if (re.test(value) === false) {
        setErrorsInput({
          ...ErrorInput,
          username: "",
        });
        setInputValues({ ...inputValues, [name]: value });
      }
    }else{
      setInputValues({ ...inputValues, [name]: value });
    }
  };
  const handleOnChangeEmail = (e: any) => {
    var email = e.target.value;

    if (validator.isEmail(email)) {
      setInputValues({ ...inputValues, email: email });
    }
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (inputValues.username === "" || phone === "") {
      setErrorsInput({
        username: inputValues.username === "" ? "يجب ادخال الاسم بالكامل" : "",
        phone: phone === "" ? "يجب ادخال رقم الجوال" : "",
      });
    } else if (phone.length < 11) {
      setErrorsInput({
        ...ErrorInput,
        phone: "رقم الجوال يجب ان لا يقل عن 9 ارقام",
      });
    } else if (inputValues.username.length < 3) {
      setErrorsInput({
        ...ErrorInput,
        username: "يرجى كتابه الاسم بالكامل",
      });
    } else {
      setLoading(false);
      SendData(inputValues, phone, id, setErrorMessage, setLoading);
    }
  };
  return (
    <form action="" onSubmit={(e) => handleSubmit(e)}>
      <div className="from-control">
        <InputOther
          Label="الاسم بالكامل"
          Id="username"
          Type="text"
          Name="username"
          OnChinge={handleOnChange}
          Value={inputValues.username}
          Placeholder="الاسم بالكامل"
          ErrorFild={ErrorInput.username}
        />
        <InputPhonecountry
          Label="رقم الجوال"
          Value={phone}
          SetValue={setPhone}
          Placeholder="523456789"
          ErrorInput={ErrorInput}
          setErrorsInput={setErrorsInput}
        />
        <InputOther
          Label="البريد الالكتروني"
          Id="email"
          Type="email"
          Name="email"
          OnChinge={handleOnChangeEmail}
          Placeholder="example@example.com"
          ErrorFild={""}
        />
      </div>
      {errorMessage !== "" ? (
        <span className="errorfiled">{errorMessage}</span>
      ) : null}

      <button
        type="submit"
        className="btn"
        disabled={loading === true ? false : true}
      >
        <span className="text">سجل اهتمامك الان</span>
      </button>
    </form>
  );
};

export default Form;
