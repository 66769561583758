import React, { createContext, useState, useContext } from 'react';

const LanguageTransitionContext = createContext();

export const useLanguageTransition = () => useContext(LanguageTransitionContext);

export const LanguageTransitionProvider = ({ children }) => {
  const [isTransitioning, setIsTransitioning] = useState(false);

  return (
    <LanguageTransitionContext.Provider value={{ isTransitioning, setIsTransitioning }}>
      {children}
    </LanguageTransitionContext.Provider>
  );
};