import { Button, useMediaQuery, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { textStyle } from "../../../shared/styles";

const StyledButton = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const handleNavigate = () => navigate("/faqs");
  const isRtl = i18n.language === "ar"; // Check if language is Arabic
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isIpad = useMediaQuery("(min-width:768px) and (max-width:1200px)");

  return (
    <Button
      onClick={handleNavigate}
      variant="contained"
      sx={{
        ...textStyle,
        mt: "32px",
        bgcolor: "transparent",
        color: "#6263AB",
        display: "flex",
        flexDirection: isRtl ? "row-reverse" : "row", // Adjust direction for RTL
        justifyContent: "center",
        alignItems: "center",
        padding: "12px 16px",
        gap: "8px",
        width: isMobile || isIpad ? "100%" : "50%",
        border: "1px solid #6263AB",
        boxShadow: "0px 20px 30px rgba(25, 28, 50, 0.05)",
        borderRadius: "14px",
        fontWeight: 700,
        fontSize: "14px",
        lineHeight: "150%",
        textTransform: "none",
        "&:hover": {
          bgcolor: "transparent",
          boxShadow: "0px 20px 30px rgba(25, 28, 50, 0.05)",
          border: "1px solid #6263AB",
          color: "#6263AB",
        },
      }}
    >
      {t("seeAllFAQ")}
    </Button>
  );
};

export default StyledButton;
