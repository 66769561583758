import React from "react";
import InternalErrorComponent from "./InternalErrorComponent";
import { Container } from "@mui/material";
import InnerNavBar from "../../components/InnerNavbar/InnerNavbar";

const InternalErrorPage: React.FC = () => {
  return (
    <>
      <Container maxWidth="lg">
        <InnerNavBar isTransparent={true} />
      </Container>
      <InternalErrorComponent />;
    </>
  );
};

export default InternalErrorPage;
