import {
  Box,
  Typography,
  Button,
  useTheme,
  useMediaQuery,
} from "@mui/material";

import { textStyle, textStyleRegular } from "../../../shared/styles";
import { useTranslation } from "react-i18next";

import { ReactComponent as NewHowItFaqIcon } from "../../../assets/faqSVG.svg";
import { useNavigate } from "react-router-dom";

const StyledBox = ({ isIpad }: any) => {
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isRtl = i18n.language === "ar"; // Check if language is Arabic
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        boxSizing: "border-box",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifySelf: "center",
        marginTop: isMobile || isIpad ? "20px" : "98px",
        gap: isMobile || isIpad ? "10px" : "15px",
        maxWidth: isMobile || isIpad ? "100%" : "400px",
        maxHeight: "400px",
        background: "#F7F7FF",
        border: "1px solid #EBEBFF",
        borderRadius: "16px",
        padding: isMobile || isIpad ? "20px" : "15px",
        textAlign: isRtl ? "right" : "left",
        marginLeft: isMobile || isIpad ? (isRtl ? "15px" : "20px") : 0, // Adjust textAlign for RTL
        marginBottom: isMobile || isIpad ? "40px" : 0,
      }}
    >
      <Box
        sx={{
          width: isMobile || isIpad ? "48px" : "64px",
          height: isMobile || isIpad ? "48px" : "64px",
          mb: isMobile || isIpad ? 2 : 0,
        }}
      >
        <NewHowItFaqIcon />
      </Box>
      <Typography
        className={isRtl ? "rtl-text" : ""}
        variant={isMobile || isIpad ? "body1" : "h6"}
        textAlign="center"
        color="#101828"
        fontWeight={700}
        sx={{ ...textStyle, mb: isMobile || isIpad ? 1 : 0 }}
      >
        {t("styledBoxTitle")}
      </Typography>
      <Typography
        className={isRtl ? "rtl-text" : ""}
        textAlign="center"
        color="#667085"
        sx={{
          fontSize: isMobile || isIpad ? "14px" : "inherit",
          ...textStyle,
        }}
      >
        {t("styledBoxDescription")}
      </Typography>
      <Button
        variant="contained"
        sx={{
          background: "#6263AB",
          boxShadow: "0px 20px 30px rgba(25, 28, 50, 0.05)",
          borderRadius: "14px",
          "&:hover": {
            background: "#6263AB",
            boxShadow: "0px 20px 30px rgba(25, 28, 50, 0.05)",
          },
          width: isMobile ? "100%" : isIpad ? "40%" : "80%", // Adjust button width for mobile
          fontWeight: 700,
          ...textStyleRegular,
        }}
        onClick={() => navigate("/support")}
      >
        {t("getInTouch")}
      </Button>
    </Box>
  );
};

export default StyledBox;
