import { Box, useTheme, useMediaQuery, Container } from "@mui/material";
import axios from "axios";

import contactUsBgImage3X from "../../../assets/ContactUSBG3X.png";
import ContactDetails from "./ContactDetails";
import ContactForm from "./ContactForm";
import { useTranslation } from "react-i18next";
import { useLanguageTransition } from "../../../LanguageTransitionContext";
import { useNavigate } from "react-router-dom";
import { baseURL } from "../../../shared/constants";

const ContactUsSection = ({ id }: any) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm")); // Check if the screen size is mobile
  const isIpad = useMediaQuery("(min-width:768px) and (max-width:1200px)");

  const { i18n } = useTranslation();
  const isRtl = i18n.language === "ar"; // Check if language is Arabic
  const { isTransitioning } = useLanguageTransition();
  const navigate = useNavigate();

  // Function to handle form submission
  const handleSubmit = async (data: any) => {
    const headers = {
      "X-Api-Version": "v2",
      "Accept-Language": i18n.language, // Using the current language from i18n
      "Content-Type": "application/json",
    };

    const dataToSend = {
      name: data.name,
      phone_number: data.mobileNumber,
      user_type: data.userType,
      inquiry_type: data.queryType,
      message: data.message,
      email: data.email,
    };
    try {
      const response = await axios.post(
        `${baseURL}/contact-us/landing-page`,
        dataToSend,
        { headers }
      );
      navigate("/contact-us-submitted", { state: { data: response.data } });
    } catch (error) {
      navigate("/something-went-wrong", { state: { data: "error" } });
    }
  };

  return (
    <div
      id={id}
      style={{
        transition: "opacity 0.5s ease, transform 0.5s ease",
        opacity: isTransitioning ? 0 : 1,
        transform: isTransitioning
          ? `translateX(${isTransitioning ? "-30px" : "30px"})`
          : "translateX(0)",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: isMobile || isIpad ? "32px" : "64px",
          backgroundImage: `url(${contactUsBgImage3X})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          height: "auto",
          margin: "20px 0",
          padding: "40px 0",
        }}
      >
        {" "}
        <Container
          maxWidth="lg"
          sx={{
            display: "flex",
            flexDirection:
              isMobile || isIpad ? "column" : isRtl ?"row": "row-reverse" , // Adjust direction for RTL
          }}
        >
          <ContactDetails isMobile={isMobile} isIpad={isIpad} />
          <ContactForm
            isMobile={isMobile}
            isIpad={isIpad}
            handleSubmit={handleSubmit}
          />{" "}
        </Container>
      </Box>
    </div>
  );
};

export default ContactUsSection;
