import React from "react";

type Props = {
  Label?: string;
  Id?: string;
  Type?: string;
  Name?: string;
  OnChinge?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  Value?: string;
  Placeholder?: string;
  ErrorFild?:string
};

const InputOther = (props: Props) => {
  const { Label, Id, Type,Name, OnChinge, Value, Placeholder,ErrorFild } = props;
  return (
    <div className="input-form">
      <label htmlFor={Id} className="form-label">
        {Label}
      </label>
      <input 
        type={Type}
        className={ErrorFild ? "form-control is-invalid" :"form-control"}
        id={Id}
        name={Name}
        onChange={OnChinge}
        value={Value}
        placeholder={Placeholder}
      />
      {ErrorFild !==''? <span className="errorfiled" >{ErrorFild}</span> : null}
    </div>
  );
};

export default InputOther;

