import { Box, Breadcrumbs, Container, Link, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { textStyle } from "../../shared/styles";
import { useTranslation } from "react-i18next";
import React from "react";

const CustomBreadcrumb = ({
  pageName,
  backgroundStyle,
  isMobile,
  isRtl,
  mainTitle,
  extraTitle = "",
}: any) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [lastScrollTop, setLastScrollTop] = React.useState(0);
  const [topMargin, setTopMargin] = React.useState("20px");

  React.useEffect(() => {
    const handleScroll = () => {
      let currentScroll = window.scrollY; // Updated to use window.scrollY
      if (currentScroll > lastScrollTop && currentScroll > 50) {
        setTopMargin("-50px"); // Reduce margin
      } else {
        setTopMargin("20px"); // Increase margin
      }
      setLastScrollTop(currentScroll);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [lastScrollTop]);

  return (
    <div
      style={{
        position: "fixed", // Make the box fixed
        width: "100%", // Stretch across the screen
        zIndex: 1000, // Ensure it's above other content (adjust as needed)
        ...backgroundStyle,
        top: 0, // Position it at the top of the viewport
      }}
    >
      <Container maxWidth="lg">
        <Box
          sx={{
            position: "static", // Make the box fixed
            top: 0, // Position it at the top of the viewport
            left: 0, // Align to the left
            right: 0, // Stretch across the screen
            zIndex: 1000, // Ensure it's above other content (adjust as needed)
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            paddingTop: isMobile ? "20%" : "7%",
            direction: isRtl ? "rtl" : "ltr",
          }}
        >
          <Breadcrumbs
            aria-label="breadcrumb"
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-start",
              width: "100%",
              height: "24px",
              padding: 0,
              flex: "none",
              alignSelf: "stretch",
              flexGrow: 0,
              zIndex: 1,
              marginTop: topMargin,
              marginLeft: "5px",
              transition: "margin-top 0.3s ease", // Smooth transition for margin-top
            }}
          >
            <Link
              underline="hover"
              color="#6263AB"
              sx={{
                ...textStyle,
                fontSize: "16px",
                fontWeight: 500,
                lineHeight: "130%",
                display: "flex",
                alignItems: "center",
                padding: "0 4px 0 0",
                gap: "4px",
                height: "21px",
                cursor: "pointer",
              }}
              onClick={() => navigate("/")}
            >
              {t("home")}
            </Link>

            <Typography
              className={isRtl ? "rtl-text" : ""}
              color="#1C1B13"
              sx={{
                ...textStyle,
                fontSize: "16px",
                fontWeight: 400,
                lineHeight: "150%",
                display: "flex",
                alignItems: "center",
                textAlign: "center",
                padding: "0 4px 0 0",
                gap: "4px",
                height: "24px",
              }}
            >
              {pageName}
            </Typography>
          </Breadcrumbs>
          <Typography
            className={isRtl ? "rtl-text" : ""}
            variant="h4"
            sx={{
              ...textStyle,
              fontWeight: 700, // Bold
              fontSize: isMobile ? "24px" : "34px", // Font size
              margin: "10px 5px",
            }}
          >
            {mainTitle}
          </Typography>

          <Typography
            className={isRtl ? "rtl-text" : ""}
            variant="h4"
            sx={{
              ...textStyle,
              fontSize: isMobile ? "12px" : "18px", // Font size
              margin: "10px 5px",
            }}
          >
            {extraTitle}
          </Typography>
        </Box>
      </Container>
    </div>
  );
};

export default CustomBreadcrumb;
