import React from "react";

import {
  Box,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import { textStyleRegular } from "../../../shared/styles";
import { useTranslation } from "react-i18next";

const ContactFormRadio = ({ isMobile, userType, setUserType }: any) => {
  const { t, i18n } = useTranslation();
  const isRtl = i18n.language === "ar"; //

  const handleUserTypeChange = (event: any) => {
    setUserType(event.target.value);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        gap: "8px",
        width: "100%",
        direction: isRtl ? "rtl" : "ltr", // Adjust direction based on language
      }}
    >
      <Typography
        className={isRtl ? "rtl-text" : ""}
        sx={{
          ...textStyleRegular,
          width: "100%",
          color: "#1C1B13",
        }}
        align={isRtl ? "right" : "left"}
      >
        {t("selectUserType")}{" "}
      </Typography>

      <RadioGroup
        row
        value={userType}
        onChange={handleUserTypeChange}
        sx={{
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        {["realEstateDeveloper", "buyer"].map((type) => (
          <FormControlLabel
            key={type}
            value={type}
            control={
              <Radio
                sx={{
                  color: "#B6B6B4", // Color for unselected state
                  "&.Mui-checked": {
                    color: "#6263AB", // Color for selected state
                  },
                }}
              />
            }
            label={t(type)}
            sx={{
              padding: "12px",
              margin: "5px 0",
              alignItems: "center",
              backgroundColor: userType === type ? "#F7F7FE" : "#FFFFFF", // Set background color based on selection
              border: "1px solid #F0F0EF",
              borderRadius: "8px",
              width: isMobile ? "100%" : "48%",
              height: "50px",
              "& .MuiFormControlLabel-label": {
                ...textStyleRegular,
                fontSize: isMobile ? "0.775rem" : "0.875rem",
              },
            }}
          />
        ))}
      </RadioGroup>
    </Box>
  );
};

export default ContactFormRadio;
