import {
  Box,
  useMediaQuery,
  useTheme,
  Container,
  Grid,
  Paper,
  Typography,
} from "@mui/material";

import DownloadAppSection from "../Home/DownloadApp";

import aboutFullMobile3XEn from "../../assets/AboutFull3xEn.png";
import aboutFullMobile3XAR from "../../assets/AboutFull3XAR.png";

import ValuesSection from "./ValuesSection";
import { textStyleRegular } from "../../shared/styles";
import { useTranslation } from "react-i18next";
import { useLanguageTransition } from "../../LanguageTransitionContext";
import React from "react";

const AboutFullComponent = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { t, i18n } = useTranslation();
  const isRtl = i18n.language === "ar";
  const { isTransitioning } = useLanguageTransition();
  const isIpad = useMediaQuery("(min-width:768px) and (max-width:1200px)");

  React.useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <div
      style={{
        transition: "opacity 0.5s ease, transform 0.5s ease",
        opacity: isTransitioning ? 0 : 1,
        transform: isTransitioning
          ? `translateX(${isTransitioning ? "-30px" : "30px"})`
          : "translateX(0)",
        marginTop: "170px",
      }}
    >
      <Container maxWidth="lg">
        <Paper
          elevation={0}
          sx={{
            pt: 4,
            overflow: "hidden",
            direction: isRtl ? "rtl" : "ltr", // Set direction based on language
          }}
        >
          <Paper elevation={0} sx={{ pt: 4 }}>
            <Grid container spacing={3}>
              <Grid
                item
                xs={isIpad ? 6 : 12}
                md={5}
                order={{ xs: isIpad ? 2 : 1, md: 2 }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "100%",
                  }}
                >
                  <img
                    src={isRtl ? aboutFullMobile3XAR : aboutFullMobile3XEn}
                    alt="Mobile"
                    width={isMobile ? "80%" : "400px"}
                    height={isMobile ? "100%" : "500px"}
                    style={{
                      margin: isMobile
                        ? isRtl
                          ? "0 0 0 50px"
                          : "0 50px 0   0"
                        : "0",
                    }}
                  />
                </Box>
              </Grid>
              <Grid
                item
                xs={isIpad ? 6 : 12}
                md={7}
                order={{ xs: isIpad ? 1 : 2, md: 1 }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: isMobile ? "center" : "flex-start",
                    justifyContent: isMobile ? "center" : "flex-start",
                    textAlign: "flex-start",
                    height: "100%",
                    width: "100%",
                  }}
                >
                  <Typography
                    className={isRtl ? "rtl-text" : ""}
                    variant="body1"
                    sx={{
                      ...textStyleRegular,
                      width: "100%",
                      fontWeight: 400, // Heading/Regular/18px
                      fontSize: "16px", // Font size
                      color: "#1C1B13", // Basic/Main Text color
                      marginBottom: "60px",
                    }}
                  >
                    {t("aboutSakText")}
                  </Typography>
                  <Box sx={{ marginBottom: "50px" }}>
                    <Typography
                      className={isRtl ? "rtl-text" : ""}
                      variant="body1"
                      sx={{
                        ...textStyleRegular,
                        width: "100%",
                        fontWeight: 400, // Heading/Regular/18px
                        fontSize: "16px", // Font size
                        color: "#1C1B13", // Basic/Main Text color
                        marginBottom: "5px",
                      }}
                    >
                      {t("mainUlAboutText1")}
                    </Typography>
                    <Typography
                      className={isRtl ? "rtl-text" : ""}
                      variant="body1"
                      sx={{
                        ...textStyleRegular,
                        width: "100%",
                        fontWeight: 400, // Heading/Regular/18px
                        fontSize: "16px", // Font size
                        color: "#1C1B13", // Basic/Main Text color
                        marginBottom: "3px",
                      }}
                    >
                      {t("subUlAboutText1")}
                    </Typography>
                    <Typography
                      className={isRtl ? "rtl-text" : ""}
                      variant="body1"
                      sx={{
                        ...textStyleRegular,
                        width: "100%",
                        fontWeight: 400, // Heading/Regular/18px
                        fontSize: "16px", // Font size
                        color: "#1C1B13", // Basic/Main Text color
                        marginBottom: "3px",
                      }}
                    >
                      {t("subUlAboutText2")}
                    </Typography>
                    <Typography
                      className={isRtl ? "rtl-text" : ""}
                      variant="body1"
                      sx={{
                        ...textStyleRegular,
                        width: "100%",
                        fontWeight: 400, // Heading/Regular/18px
                        fontSize: "16px", // Font size
                        color: "#1C1B13", // Basic/Main Text color
                        marginBottom: "3px",
                      }}
                    >
                      {t("subUlAboutText3")}
                    </Typography>
                    <Typography
                      className={isRtl ? "rtl-text" : ""}
                      variant="body1"
                      sx={{
                        ...textStyleRegular,
                        width: "100%",
                        fontWeight: 400, // Heading/Regular/18px
                        fontSize: "16px", // Font size
                        color: "#1C1B13", // Basic/Main Text color
                        marginBottom: "3px",
                      }}
                    >
                      {t("subUlAboutText4")}
                    </Typography>
                    <Typography
                      className={isRtl ? "rtl-text" : ""}
                      variant="body1"
                      sx={{
                        ...textStyleRegular,
                        width: "100%",
                        fontWeight: 400, // Heading/Regular/18px
                        fontSize: "16px", // Font size
                        color: "#1C1B13", // Basic/Main Text color
                        marginBottom: "3px",
                      }}
                    >
                      {t("subUlAboutText5")}
                    </Typography>
                  </Box>
                  <Box sx={{ marginBottom: "50px" }}>
                    <Typography
                      className={isRtl ? "rtl-text" : ""}
                      variant="body1"
                      sx={{
                        ...textStyleRegular,
                        width: "100%",
                        fontWeight: 400, // Heading/Regular/18px
                        fontSize: "16px", // Font size
                        color: "#1C1B13", // Basic/Main Text color
                        marginBottom: "5px",
                      }}
                    >
                      {t("mainUlAboutText2")}
                    </Typography>
                    <Typography
                      className={isRtl ? "rtl-text" : ""}
                      variant="body1"
                      sx={{
                        ...textStyleRegular,
                        width: "100%",
                        fontWeight: 400, // Heading/Regular/18px
                        fontSize: "16px", // Font size
                        color: "#1C1B13", // Basic/Main Text color
                        marginBottom: "3px",
                      }}
                    >
                      {t("subUlAboutText6")}
                    </Typography>
                    <Typography
                      className={isRtl ? "rtl-text" : ""}
                      variant="body1"
                      sx={{
                        ...textStyleRegular,
                        width: "100%",
                        fontWeight: 400, // Heading/Regular/18px
                        fontSize: "16px", // Font size
                        color: "#1C1B13", // Basic/Main Text color
                        marginBottom: "3px",
                      }}
                    >
                      {t("subUlAboutText7")}
                    </Typography>
                    <Typography
                      className={isRtl ? "rtl-text" : ""}
                      variant="body1"
                      sx={{
                        ...textStyleRegular,
                        width: "100%",
                        fontWeight: 400, // Heading/Regular/18px
                        fontSize: "16px", // Font size
                        color: "#1C1B13", // Basic/Main Text color
                        marginBottom: "3px",
                      }}
                    >
                      {t("subUlAboutText8")}
                    </Typography>
                    <Typography
                      className={isRtl ? "rtl-text" : ""}
                      variant="body1"
                      sx={{
                        ...textStyleRegular,
                        width: "100%",
                        fontWeight: 400, // Heading/Regular/18px
                        fontSize: "16px", // Font size
                        color: "#1C1B13", // Basic/Main Text color
                        marginBottom: "3px",
                      }}
                    >
                      {t("subUlAboutText9")}
                    </Typography>
                    <Typography
                      className={isRtl ? "rtl-text" : ""}
                      variant="body1"
                      sx={{
                        ...textStyleRegular,
                        width: "100%",
                        fontWeight: 400, // Heading/Regular/18px
                        fontSize: "16px", // Font size
                        color: "#1C1B13", // Basic/Main Text color
                        marginBottom: "3px",
                      }}
                    >
                      {t("subUlAboutText10")}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
            <ValuesSection />
          </Paper>
        </Paper>
      </Container>
      <DownloadAppSection showArrow={false} id="download" />
    </div>
  );
};

export default AboutFullComponent;
