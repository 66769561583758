// i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Import your translation files
import translationEN from '../src/locales/en.json';
import translationAR from '../src/locales/ar.json';

// the translations
const resources = {
  en: {
    translation: translationEN
  },
  ar: {
    translation: translationAR
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: 'ar', // default language

    keySeparator: false, // to support nested translations

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;
