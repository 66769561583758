import { Box, Typography, useTheme, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next"; // Import useTranslation

import { textStyleRegular } from "../../../shared/styles";

export const StepItem = ({ icon, title, description }: any) => {
  const { i18n } = useTranslation(); // Get the current language
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isRtl = i18n.language === "ar"; // Check if language is Arabic

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: isRtl ? "row" : "row",
        alignItems: "flex-start",
        padding: "0px",
        gap: "16px",
        width: "100%",
      }}
    >
      <Box
        sx={{
          width: isMobile ? "32px" : "48px",
          height: isMobile ? "32px" : "48px",
          background: "#FFFFFF",
          borderRadius: "8px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box sx={{ width: "100%", height: "100%" }}>{icon}</Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          textAlign: isRtl ? "right" : "left",
        }}
      >
        <Typography
          className={isRtl ? "rtl-text" : ""}
          variant={"body1"}
          sx={{
            ...textStyleRegular,
            fontWeight: "bold",
            color: "#1C1B13",
            fontSize: "18px",
          }}
        >
          {title}
        </Typography>
        <Typography
          className={isRtl ? "rtl-text" : ""}
          variant="caption"
          sx={{
            ...textStyleRegular,
            fontWeight: "normal",
            color: "#1C1B13",
            fontSize: "14px",
          }}
        >
          {description}
        </Typography>
      </Box>
    </Box>
  );
};
