import React from "react";
import ForbiddenErrorComponent from "./ForbiddenErrorComponent";
import { Container } from "@mui/material";
import InnerNavBar from "../../components/InnerNavbar/InnerNavbar";

const ForbiddenErrorPage: React.FC = () => {
  return (
    <>
      <Container maxWidth="lg">
        <InnerNavBar isTransparent={true} />
      </Container>
      <ForbiddenErrorComponent />;
    </>
  );
};

export default ForbiddenErrorPage;
