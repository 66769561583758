import { Box, useMediaQuery, useTheme, Divider, Grid } from "@mui/material";
import React from "react";
import { styled } from "@mui/material/styles";

import { ReactComponent as NewGoalIcon } from "../../assets/goalSVG.svg";
import { ReactComponent as NewMissionIcon } from "../../assets/missionSVG.svg";
import { ReactComponent as NewVesionIcon } from "../../assets/vesionSVG.svg";

import MyServiceCard from "./ServiceCard";
import { useTranslation } from "react-i18next";

const StyledDivider = styled(Divider)(({ theme }) => ({
  alignSelf: "stretch",
  borderLeft: `1px solid ${theme.palette.divider} #6263AB`,
  opacity: 0.5,
}));

const ValuesSection: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isIpad = useMediaQuery("(min-width:768px) and (max-width:1200px)");
  const isTablet = useMediaQuery("(max-width: 1024px)"); // iPad size

  const { t } = useTranslation();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: isMobile ? "column" : "row",
        justifyContent: "space-between",
        alignItems: "center",
        border: 0,
      }}
    >
      <Grid container>
        <MyServiceCard
          title={t("ourGoal")}
          description={t("ourGoalDesc")}
          icon={<NewGoalIcon />}
        />
        {!isIpad ||
          (isTablet && <StyledDivider orientation="vertical" flexItem />)}
        <MyServiceCard
          title={t("ourMission")}
          description={t("ourMissionDesc")}
          icon={<NewMissionIcon />}
        />
        {!isIpad ||
          (isTablet && <StyledDivider orientation="vertical" flexItem />)}
        <MyServiceCard
          title={t("ourVision")}
          description={t("ourVisionDesc")}
          icon={<NewVesionIcon />}
        />
      </Grid>
    </Box>
  );
};

export default ValuesSection;
