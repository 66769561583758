import React from "react";
import {
  DialogContent,
  DialogTitle,
  Typography,
  Box,
  useTheme,
  Drawer,
} from "@mui/material";
import { textStyle, textStyleRegular } from "../../shared/styles";
import discoverIcon3X from "../../assets/explore3XLast.png";

import { useTranslation } from "react-i18next";
import { StyledButton } from "./StyledButton";

const ExplorePropertiesBottomSheet = ({ open, onClose }: any) => {
  const theme = useTheme();

  const { t, i18n } = useTranslation();
  const isRtl = i18n.language === "ar"; // Check if language is Arabic

  React.useEffect(() => {
    const img = new Image();
    img.src = discoverIcon3X;
  }, []);

  return (
    <Drawer
      anchor="bottom"
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          boxShadow: "0px 4px 10px rgba(200, 200, 200, 0.3)",
          borderRadius: "24px 24px 0 0", // Rounded corners on the top side
          padding: theme.spacing(2),
          minHeight: "30vh", // Adjust the height of the bottom sheet
          maxHeight: "90vh",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          overflow: "hidden",
          maxWidth: "100%",
          padding: " 0 20px",
          backgroundColor: "#F7F7FF",
          borderRadius: "20px",
        }}
      >
        {open && (
          <img
            src={discoverIcon3X}
            alt="Explore"
            style={{ maxWidth: "100%", height: "auto" }}
          />
        )}
      </Box>
      <DialogTitle
        sx={{
          ...textStyle,
          fontSize: "18px",
          color: "#1C1B13",
          textAlign: "center",
        }}
      >
        <Typography
          className={isRtl ? "rtl-text" : ""}
          sx={{
            ...textStyle,
            color: "#1C1B13",
            textAlign: "center",
            fontWeight: 700,
            fontSize: 18,
            flexGrow: 1, // Allow the text to take available space
          }}
        >
          {t("discoverTitle")}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography
          className={isRtl ? "rtl-text" : ""}
          gutterBottom
          sx={{
            ...textStyleRegular,
            color: "#1C1B13",
            textAlign: "center",
          }}
        >
          {t("discoverDesc")}
        </Typography>
        <StyledButton onClick={onClose}>{t("Close")}</StyledButton>{" "}
      </DialogContent>
    </Drawer>
  );
};

export default ExplorePropertiesBottomSheet;
