import {
  Typography,
  Card,
  CardContent,
  Grid,
  useMediaQuery,
} from "@mui/material";
import React from "react";
import { textStyle, textStyleRegular } from "../../shared/styles";
import { styled } from "@mui/material/styles";

const ServiceCard = styled(Card)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: theme.spacing(4, 3),
  gap: theme.spacing(3),
  margin: "0 auto",
  textAlign: "center",
  boxShadow: "none", // Explicitly override any shadow
  border: "none", // Remove border if any
}));

const ServiceIcon = styled("div")({
  width: 64,
  height: 64,
});

const ServiceText = styled(Typography)({
  ...textStyleRegular,
});

const ServiceTitle = styled(Typography)({
  ...textStyle,
});

// Define the props for the component
interface ServiceCardProps {
  title: string;
  description: string;
  icon: any;
}

// Create the ServiceCard component
const MyServiceCard: React.FC<ServiceCardProps> = ({
  title,
  description,
  icon,
}) => {
  const isIpad = useMediaQuery("(min-width:768px) and (max-width:1200px)");
  const isTablet = useMediaQuery("(max-width: 1024px)"); // iPad size

  return (
    <Grid
      item
      xs={12}
      sm={isIpad || isTablet ? 3.9 : 6}
      md={isIpad || isTablet ? 3.9 : 4}
      lg={3.9}
    >
      <ServiceCard>
        <ServiceIcon>{icon}</ServiceIcon>
        <CardContent>
          <ServiceTitle>{title}</ServiceTitle>
          <ServiceText>{description}</ServiceText>
        </CardContent>
      </ServiceCard>
    </Grid>
  );
};

export default MyServiceCard;
