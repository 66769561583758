import React from "react";
import {
  Box,
  Grid,
  Typography,
  Paper,
  Button,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import { textStyle, textStyleRegular } from "../../../shared/styles";
import { useTranslation } from "react-i18next";
import MultiPaymentPopup from "../../../components/Pop-ups/MultiPaymentsPopUp";
import SellOnMapPopup from "../../../components/Pop-ups/SellOnMapPopUp";
import ExplorePropertiesPopUp from "../../../components/Pop-ups/ExplorePropertiesPopUp";
import DiscoverPopUp from "../../../components/Pop-ups/DiscoverPopUp";

import { ReactComponent as NewFeature1Icon } from "../../../assets/feature1SVG.svg";
import { ReactComponent as NewFeature2Icon } from "../../../assets/features2SVG.svg";
import { ReactComponent as NewFeature3Icon } from "../../../assets/feature3SVG.svg";
import { ReactComponent as NewFeature4Icon } from "../../../assets/features4SVG.svg";
import MultiPaymentBottomSheet from "../../../components/BottomSheets/MultiPaymentsBottomSheet";
import SellOnMapBottomSheet from "../../../components/BottomSheets/SellOnMapBottomSheet";
import ExplorePropertiesBottomSheet from "../../../components/BottomSheets/ExplorePropertiesBottomSheet";
import DiscoverBottomSheet from "../../../components/BottomSheets/DiscoverBottomSheet";

interface ServiceCardProps {
  title: string;
  description: string;
  icon: any;
  onHover: () => void; // Added prop for hover handling
}

// ServiceCard component with styles applied
const ServiceCard: React.FC<ServiceCardProps> = ({
  title,
  description,
  icon,
  onHover,
}) => {
  const [isHovered, setIsHovered] = React.useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isIpad = useMediaQuery("(min-width:768px) and (max-width:1200px)");

  const { t, i18n } = useTranslation();

  const isRtl = i18n.language === "ar"; // Check if language is Arabic

  return (
    <>
      {isMobile || isIpad ? (
        <>
          <Paper
            onClick={onHover}
            sx={{
              position: "relative",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              padding: "16px",
              gap: "24px",
              minWidth: "300px",
              background: "#FFFFFF",
              border: "1px solid #F0F0EF",
              boxShadow: "0px 14px 46px rgba(215, 221, 224, 0.2)",
              borderRadius: "16px",
              overflow: "hidden",
              minHeight: "300px",
            }}
          >
            <Box sx={{ width: "64px", height: "64px" }}>{icon}</Box>
            <Typography
              className={isRtl ? "rtl-text" : ""}
              variant="h6"
              sx={{
                ...textStyle,
                fontWeight: "700",
                fontSize: "22px",
                lineHeight: "130%",
                color: "#1C1B13",
                textAlign: "center",
              }}
            >
              {title}
            </Typography>
            <Typography
              className={isRtl ? "rtl-text" : ""}
              sx={{
                ...textStyleRegular,
                fontWeight: "400",
                fontSize: "16px",
                lineHeight: "150%",
                color: "#1C1B13",
                textAlign: "center",
              }}
            >
              {description}
            </Typography>
            <Box>
              <Typography
                className={isRtl ? "rtl-text" : ""}
                sx={{
                  ...textStyle,
                  fontWeight: "700",
                  fontSize: "14px",
                  lineHeight: "150%",
                  color: "#6263AB",
                }}
              >
                {t("readMore")}{" "}
              </Typography>
            </Box>
          </Paper>
        </>
      ) : (
        <>
          <Paper
            onMouseOver={() => setIsHovered(true)}
            onMouseOut={() => setIsHovered(false)}
            onClick={onHover}
            sx={{
              position: "relative",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              padding: "16px",
              gap: "24px",
              minWidth: "300px",
              background: "#FFFFFF",
              border: "1px solid #F0F0EF",
              boxShadow: "0px 14px 46px rgba(215, 221, 224, 0.2)",
              borderRadius: "16px",
              overflow: "hidden",
              minHeight: "300px",
              marginLeft: isMobile ? "0" : "2%",
              marginBottom: isMobile ? "0" : "2%",
              transition: "box-shadow 0.3s ease, transform 0.3s ease", // Apply transition to both properties
              "&:hover": {
                boxShadow: "0px 20px 60px rgba(215, 221, 224, 0.4)", // Enhance shadow on hover
                transform: "scale(1.05)", // Scale up slightly on hover
                backgroundColor: "#F7F7FF",
                borderColor: "#6263AB",
              },
            }}
          >
            <Box sx={{ width: "64px", height: "64px" }}>{icon}</Box>
            <Typography
              className={isRtl ? "rtl-text" : ""}
              variant="h6"
              sx={{
                ...textStyle,
                fontWeight: "700",
                fontSize: "22px",
                lineHeight: "130%",
                color: "#1C1B13",
                textAlign: "center",
              }}
            >
              {title}
            </Typography>
            <Typography
              className={isRtl ? "rtl-text" : ""}
              sx={{
                ...textStyleRegular,
                fontWeight: "400",
                fontSize: "16px",
                lineHeight: "150%",
                color: "#1C1B13",
                textAlign: "center",
              }}
            >
              {description}
            </Typography>
            {isHovered && (
              <Box
                sx={{
                  position: "absolute",
                  bottom: "-91px",
                  left: "50%",
                  width: "450px",
                  height: "130px",
                  bgcolor: "#6263AB",
                  borderRadius: "70% 70% 0 0 / 100% 100% 0 0", // Circular segment on top
                  transform: isHovered
                    ? "translateX(-50%) translateY(0)"
                    : "translateX(-50%) translateY(10px)",
                  opacity: isHovered ? 1 : 0,
                  visibility: isHovered ? "visible" : "hidden",
                  transition: "opacity 1s ease, transform 1s ease",
                  pointerEvents: isHovered ? "auto" : "none", // Prevents interaction when hidden
                }}
              >
                <Typography
                  className={isRtl ? "rtl-text" : ""}
                  sx={{
                    ...textStyle,
                    fontWeight: "700",
                    fontSize: "14px",
                    lineHeight: "150%",
                    color: "#FFFFFF",
                    textDecoration: "underline",
                    textAlign: "center",
                  }}
                >
                  <Button
                    sx={{
                      ...textStyle,
                      fontWeight: "700",
                      lineHeight: "150%",
                      color: "#FFFFFF",
                      textAlign: "center",
                      mt: "5px",
                      textTransform: "capitalize",
                    }}
                  >
                    {t("readMore")}{" "}
                  </Button>
                </Typography>
              </Box>
            )}
          </Paper>
        </>
      )}
    </>
  );
};

// Main component for rendering the services section
const ServicesSection: React.FC = () => {
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isIpad = useMediaQuery("(min-width:768px) and (max-width:1200px)");

  const [openMultiplePayments, setOpenMultiplePayments] = React.useState(false);
  const [openSellPopUp, setOpenSellPopUp] = React.useState(false);
  const [openExplorePopUp, setOpenExplorePopUp] = React.useState(false);
  const [openDiscoverPopUp, setOpenDiscoverPopUp] = React.useState(false);

  const handleClosePaymentsPopUp = () => setOpenMultiplePayments(false);
  const handleOpenPaymentsPopUp = () => setOpenMultiplePayments(true);
  const handleCloseSellPopUp = () => setOpenSellPopUp(false);
  const handleOpenSellPopUp = () => setOpenSellPopUp(true);
  const handleCloseExplorePopUp = () => setOpenExplorePopUp(false);
  const handleOpenExplorePopUp = () => setOpenExplorePopUp(true);

  const handleCloseDiscoverPopUp = () => setOpenDiscoverPopUp(false);
  const handleOpenDiscoverPopUp = () => setOpenDiscoverPopUp(true);

  const isRtl = i18n.language === "ar";
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        alignItems: "flex-start",
        alignContent: "flex-start",
        gap: "24px",
        direction: isRtl ? "rtl" : "ltr", // Adjust direction based on language
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={isIpad ? 6 : 12} md={6}>
          <ServiceCard
            title={t("multiPaymentMethods")}
            description={t("multiPaymentDescription")}
            icon={<NewFeature1Icon />}
            onHover={handleOpenPaymentsPopUp}
          />
        </Grid>
        <Grid item xs={isIpad ? 6 : 12} md={6}>
          <ServiceCard
            title={t("sellOnMap")}
            description={t("sellOnMapDescription")}
            icon={<NewFeature2Icon />}
            onHover={handleOpenSellPopUp}
          />
        </Grid>
        <Grid item xs={isIpad ? 6 : 12} md={6}>
          <ServiceCard
            title={t("uniqueBrowsing")}
            description={t("uniqueBrowsingDescription")}
            icon={<NewFeature3Icon />}
            onHover={handleOpenExplorePopUp}
          />
        </Grid>
        <Grid item xs={isIpad ? 6 : 12} md={6}>
          <ServiceCard
            title={t("discoverUsingMaps")}
            description={t("discoverMapsDescription")}
            icon={<NewFeature4Icon />}
            onHover={handleOpenDiscoverPopUp}
          />
        </Grid>
      </Grid>
      {isMobile ? (
        <>
          <MultiPaymentBottomSheet
            open={openMultiplePayments}
            onClose={handleClosePaymentsPopUp}
          />

          <SellOnMapBottomSheet
            open={openSellPopUp}
            onClose={handleCloseSellPopUp}
          />
          <ExplorePropertiesBottomSheet
            open={openExplorePopUp}
            onClose={handleCloseExplorePopUp}
          />
          <DiscoverBottomSheet
            open={openDiscoverPopUp}
            onClose={handleCloseDiscoverPopUp}
          />
        </>
      ) : (
        <>
          <MultiPaymentPopup
            open={openMultiplePayments}
            onClose={handleClosePaymentsPopUp}
            isIpad={isIpad}
          />

          <SellOnMapPopup
            open={openSellPopUp}
            onClose={handleCloseSellPopUp}
            isIpad={isIpad}
          />
          <ExplorePropertiesPopUp
            open={openExplorePopUp}
            onClose={handleCloseExplorePopUp}
            isIpad={isIpad}
          />
          <DiscoverPopUp
            open={openDiscoverPopUp}
            onClose={handleCloseDiscoverPopUp}
            isIpad={isIpad}
          />
        </>
      )}
    </Box>
  );
};

export default ServicesSection;
