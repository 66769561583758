// MainPage.tsx
import React from "react";
import NavbarLanding from "../../components/navbar-landing/index";
import IconAppStore from "../../components/images/icon-app-store.png";
import IconGooglePlay from "../../components/images/icon-google-store.png";
import { DotLottiePlayer } from '@dotlottie/react-player';

import "@dotlottie/react-player/dist/index.css";
const SuccessPage: React.FC = () => {
  return (
    <main
      className="main-page-success"
      style={{
        backgroundColor: "#fff",
      }}
    >
      <NavbarLanding />
      <div className="success">
        <div className="container">
          <div className="success__flex-container w-100 h-100">
            <div className="success-image">
              <DotLottiePlayer
                src="https://lottie.host/86cd6edd-f374-44d8-9c32-1e618ad4dfc1/oIYpBQy6Jm.json"
                autoplay
              />
            </div>
            <div className="content">
              <div className="top">
                <h6>تم تسجيل اهتمامك بنجاح</h6>
                <p>
                  شكرا لتسجيل اهتمامك بالمشروع سوف يتواصل معك فريق المبيعات.
                </p>
              </div>

              <div className="bottom">
                <p>قم بتحميل التطبيق لمشاهدة العقارات المتاحة في المشروع</p>
                <div className="list">
                  <a
                    href="https://app.adjust.com/1emmgtqe?engagement_type=fallback_click"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={IconAppStore} alt="app store" />
                  </a>
                  <a
                    href="https://app.adjust.com/1ed1pde7?engagement_type=fallback_click"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={IconGooglePlay} alt="app store" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default SuccessPage;
