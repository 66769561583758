import { Box, Typography, TextField } from "@mui/material";
import { textStyleRegular } from "../../../shared/styles";
import { useTranslation } from "react-i18next";

const EmailField = ({ email, setEmail }: any) => {
  const { t, i18n } = useTranslation();
  const isRtl = i18n.language === "ar";
  return (
    <Box
      sx={{
        width: "100%",
        direction: isRtl ? "rtl" : "ltr", // Adjust direction based on language
      }}
    >
      <Typography
        className={isRtl ? "rtl-text" : ""}
        variant="body1"
        sx={{ ...textStyleRegular, mb: 1, color: "#1C1B13" }}
      >
        {t("email")}{" "}
      </Typography>
      <TextField
        fullWidth
        multiline
        value={email}
        variant="outlined"
        placeholder={t("email")}
        onChange={(e) => setEmail(e.target.value)}
        sx={{
          background: "#FFFFFF",
          border: "1px solid #F0F0EF",
          boxShadow: "0px 4px 12px rgba(196, 196, 196, 0.1)",
          borderRadius: "8px",
          "& .MuiInputBase-root": {
            ...textStyleRegular,
            fontSize: "14px",
            color: "#1C1B13",
          },
          "& .MuiOutlinedInput-notchedOutline": {
            border: "none",
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "#B6B6B4",
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#6263AB",
          },
        }}
      />
    </Box>
  );
};

export default EmailField;
