import React from "react";
import {
  Box,
  Typography,
  Grid,
  useMediaQuery,
  useTheme,
  Container,
} from "@mui/material";

import heroImageEng from "../../../assets/HeroSection3XEng.png";
import heroImageAr from "../../../assets/latestHeroMobileImage.png";
import heroBgEn from "../../../assets/HeroSectionBG3XEn.png";
import heroBgAr from "../../../assets/HeroBG3XAR.png";

import { textStyle, textStyleRegular } from "../../../shared/styles";
import { useTranslation } from "react-i18next";
import { useLanguageTransition } from "../../../LanguageTransitionContext";
import googlePlayBadge3X from "../../../assets/GooglePlayBadge3X.png";
import appleStoreBadge3X from "../../../assets/AppleStoreBadge3X.png";

const HeroComponent = ({ id }: any) => {
  const { t, i18n } = useTranslation();
  const isRtl = i18n.language === "ar";
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isIpad = useMediaQuery("(min-width:768px) and (max-width:1200px)");

  const { isTransitioning } = useLanguageTransition();
  const [, setOpacity] = React.useState(1);

  const handleScroll = () => {
    // Calculate the opacity based on the scroll position
    const newOpacity = Math.max(0.9, 1 - window.scrollY / 300);
    setOpacity(newOpacity);
  };

  React.useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div
      id={id}
      style={{
        transition: "opacity 0.5s ease, transform 0.5s ease",
        opacity: isTransitioning ? 0 : 1,
        transform: isTransitioning
          ? `translateX(${isTransitioning ? "-30px" : "30px"})`
          : "translateX(0)",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          // padding: "60px 0px 0px",
          gap: "24px",
          backgroundImage: isRtl ? `url(${heroBgAr})` : `url(${heroBgEn})`,
          backgroundSize: isRtl ? "100% 98%" : "100% 96%", // Cover the entire width
          backgroundPosition: "center top", // This is the current position
          backgroundRepeat: "no-repeat",
          direction: isRtl ? "rtl" : "ltr",
          padding: isIpad
            ? "60px 60px"
            : isMobile
            ? "60px 0px 0px"
            : "60px 0px 0px",
          height: isIpad ? "auto" : isMobile ? "auto" : "800px",
        }}
      >
        <Container maxWidth="lg">
          <Grid container spacing={isMobile ? 2 : 4}>
            <Grid item xs={12} sm={7} md={7} lg={6}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "30px",
                  width: "100%",
                  padding: isMobile ? "20px" : "0px",
                  marginTop: isMobile ? "0px" : "80px",
                }}
              >
                <Typography
                  className={isRtl ? "rtl-text" : ""}
                  sx={{
                    ...textStyle,
                    fontWeight: 500,
                    fontSize: isMobile ? "24px" : isIpad ? "34px" : "56px",
                    lineHeight: "130%",
                    color: "#1C1B13",
                    width: "100%",
                    textAlign: isMobile ? "center" : isRtl ? "right" : "left",
                    maxWidth: "660px",
                  }}
                >
                  {t("heroTitle")}{" "}
                  <span
                    style={{
                      color: "#EBDD36",
                      textShadow: `
                      -0.2px -0.2px 0 #C0C0C0,  
                      0.2px -0.2px 0 #C0C0C0,
                      -0.2px 0.2px 0 #C0C0C0,
                      0.2px 0.2px 0 #C0C0C0`, // Creates a gray 'border' around each letter
                    }}
                  >
                    {t("heroTitleHighlight")}{" "}
                  </span>
                  {t("withSak")}
                </Typography>
                <Typography
                  className={isRtl ? "rtl-text" : ""}
                  sx={{
                    ...textStyleRegular,
                    fontSize: isMobile ? "16px" : "18px",
                    lineHeight: "130%",
                    color: "#1C1B13",
                    width: "100%",
                    fontWeight: 500,
                    textAlign: isMobile ? "center" : isRtl ? "right" : "left",
                    maxWidth: "600px",
                  }}
                >
                  {t("heroSubtitle")}
                </Typography>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: isMobile
                      ? "center"
                      : isRtl
                      ? "flex-start"
                      : "flex-start",
                    flexWrap: "wrap",
                  }}
                  gap={2}
                >
                  <Box
                    component="a"
                    href="https://app.adjust.com/17q1d5fo"
                    target="_blank"
                  >
                    <img
                      src={googlePlayBadge3X}
                      alt="Get it on Google Play"
                      style={{ width: isMobile || isIpad ? "140px" : "200px" }}
                    />
                  </Box>
                  <Box
                    component="a"
                    href="https://app.adjust.com/17b556zr"
                    target="_blank"
                  >
                    <img
                      src={appleStoreBadge3X}
                      style={{ width: isMobile || isIpad ? "140px" : "200px" }}
                      alt="Download on the App Store"
                    />
                  </Box>
                </Box>
              </Box>
              {/* <ReviewsSection /> */}
            </Grid>
            <Grid item xs={12} sm={5} md={5} lg={6}>
              <img
                src={isRtl ? heroImageAr : heroImageEng}
                alt="Mobile"
                style={{
                  width: isMobile ? "100%" : "120%",
                  height: "auto",
                }}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </div>
  );
};

export default HeroComponent;
