import React from "react";
import ContactUsSubmittedComponent from "./ContactUsSubmittedComponent";
import { Container } from "@mui/material";
import InnerNavBar from "../../components/InnerNavbar/InnerNavbar";
import CustomHelmet from "../../components/Helmet/Helmet";
import { useTranslation } from "react-i18next";

const ContactUsSubmittedPage: React.FC = () => {
  const { t } = useTranslation();
  return (
    <>
      <CustomHelmet title={t("sak") + " - " + t("contactUs")} />
      <Container maxWidth="lg" sx={{ margin: "20px 0" }}>
        <InnerNavBar isTransparent={true} />
      </Container>
      <ContactUsSubmittedComponent />
    </>
  );
};

export default ContactUsSubmittedPage;
