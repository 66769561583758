import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

// Import your components
import Home from "./pages/Home";
import AboutFullPage from "./pages/AboutFull";
import PrivacyAndPolicyPage from "./pages/PrivacyAndPolicy";
import FaqsFullPage from "./pages/FaqsFull";
import { LanguageTransitionProvider } from "./LanguageTransitionContext";
import NotFoundPage from "./pages/NotFound";
// import ContactFullPage from "./pages/ContactUsFull";

import "./i18n";
import ContactUsSubmittedPage from "./pages/ContactUsSubmitted";
import InternalErrorPage from "./pages/InternalError";
import ContactFullPage from "./pages/ContactUsFull";
import ForbiddenErrorPage from "./pages/ForbidenError";
import LandingPage from "./pages/landing-page/index";
import SuccessPage from "./pages/success/index";

function App() {
  return (
    <LanguageTransitionProvider>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about-sak" element={<AboutFullPage />} />
          <Route path="/privacy-policy-ar" element={<PrivacyAndPolicyPage />} />
          <Route path="/faqs" element={<FaqsFullPage />} />
          <Route
            path="/contact-us-submitted"
            element={<ContactUsSubmittedPage />}
          />
          <Route path="/something-went-wrong" element={<InternalErrorPage />} />
          <Route path="/not-allowed" element={<ForbiddenErrorPage />} />

          <Route path="/support" element={<ContactFullPage />} />
          <Route path="*" element={<NotFoundPage />} />
          <Route path="/projects/:id" element={<LandingPage />} />
          <Route path="/thank-you" element={<SuccessPage />} />
        </Routes>
      </Router>
    </LanguageTransitionProvider>
  );
}

export default App;
