
import Form from "./form";
//import parse from "html-react-parser";
//import ReactDOM from "react-dom";

type Props = {
  className?: any;
};

const LandingPageForm = (props: Props) => {
  const { className } = props;

  return (
    <div className={`landing-page__form ${className}`}>
      <div className="form__title">
        <h5> لا تفوتك فرصة التملك في المشروع! </h5>
        <p> سجل الآن لتكون على أول قائمة المهتمين وسيتم التواصل معك من قبل فريق المبيعات. </p>
      </div>
      <Form/>
    </div>
  );
};

export default LandingPageForm;
