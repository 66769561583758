import React from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  IconButton,
  Box,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { textStyle } from "../../shared/styles";
import sellOnMap3X from "../../assets/SellOnMap3X.png";
import { ReactComponent as WafiIcon } from "../../assets/wafiSVG.svg";

import { useTranslation } from "react-i18next";

const SellOnMapPopup = ({ open, onClose, isIpad }: any) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { t, i18n } = useTranslation();
  const isRtl = i18n.language === "ar";

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullScreen={isMobile || isIpad}
      PaperProps={{
        sx: {
          padding: "24px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "24px",
          boxShadow: "0px 4px 10px rgba(200, 200, 200, 0.3)",
          borderRadius: "24px",
          minWidth: isIpad ? "80%" : "55%",
          width: isMobile || isIpad ? "90%" : "500px",
          height: isMobile || isIpad ? "60%" : "auto",
          maxHeight: isMobile ? "90%" : "auto",
        },
      }}
    >
      <DialogTitle
        sx={{
          ...textStyle,
          fontSize: isMobile ? "24px" : "32px", // Smaller font size on mobile
          lineHeight: "130%",
          color: "#1C1B13",
          textAlign: "center",
          margin: isIpad ? "20px 0 " : "auto",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center", // Vertically center the items
            justifyContent: "space-between", // Space between text and image
            width: "100%", // Use full width of the container
            gap: "20px", // Space between text and image
            padding: "20px", // Padding around the container
            flexDirection: isRtl ? "row-reverse" : "row", // Reverse the direction for RTL languages
          }}
        >
          <Typography
            className={isRtl ? "rtl-text" : ""}
            sx={{
              ...textStyle,
              color: "#1C1B13",
              textAlign: "center",
              fontWeight: 700,

              fontSize: isMobile ? "24px" : "32px", // Smaller font size on mobile
              flexGrow: 1, // Allow the text to take available space
            }}
          >
            {t("sellOnMapTitle")}
          </Typography>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              overflow: "hidden",
              maxWidth: "50%", // Adjust the width of the image container
            }}
          >
            <WafiIcon />
          </Box>
        </Box>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Typography
          className={isRtl ? "rtl-text" : ""}
          gutterBottom
          sx={{
            ...textStyle,
            color: "#1C1B13",
            textAlign: "center",
            fontSize: "18px", // Smaller font size on mobile
            margin: isMobile || isIpad ? "0 10px" : "0 30px",
          }}
        >
          {t("sellOnMapDesc")}
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            overflow: "hidden",
            maxWidth: "100%",
            padding: "20px",
            marginTop: "40px",
          }}
        >
          <img
            src={sellOnMap3X}
            alt="Sell on Map"
            style={{ maxWidth: "100%", height: "auto" }}
          />
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default SellOnMapPopup;
