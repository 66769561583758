export const textStyleMedium = {
  fontFamily: "DIN Next LT Arabic Medium",
};

export const textStyleRegular = {
  fontFamily: "DIN Next LT Arabic Regular",
};

export const textStyle = {
  fontFamily: "DIN Next LT Arabic Medium",
};
