import React from "react";
import IconTotalArea from "../../images/icons/icon-total_area.png";
import IconBuildingsNumber from "../../images/icons/icon-buildings_number.png";
import IconPropertiesNumber from "../../images/icons/icon-properties_number.png";
import IconBlocksNumber from "../../images/icons/icon-blocks_number.png";

type Props = {
  projectDetails: any;
};
const LandingPageBasicInfo = (props: Props) => {
  const { projectDetails } = props;

  return (
    <div className="landing-page__content__basic-info">
      {projectDetails.map((project: any, index: number) => (
        <BasicInfoCard
          project={project}
          index={index}
          projectDetails={projectDetails}
          key={index}
        />
      ))}
    </div>
  );
};

export default LandingPageBasicInfo;

const BasicInfoCard = (props: any) => {
  const { project, index, projectDetails } = props;
  return (
    <>
      <div className="landing-page__content__basic-info__card">
        <span className="landing-page__content__basic-info__card__icon">
          {project.slug === "total_area" && <img src={IconTotalArea} alt="" />}
          {project.slug === "buildings_number" && (
            <img src={IconBuildingsNumber} alt="" />
          )}

          {project.slug === "properties_number" && (
            <img src={IconPropertiesNumber} alt="" />
          )}

          {project.slug === "blocks_number" && (
            <img src={IconBlocksNumber} alt="" />
          )}
        </span>
        <span className="landing-page__content__basic-info__card__text">
          <h6>{project.name}</h6>{" "}
          <p>
            {project.slug === "total_area" ? (
              <>
                {project.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                م<sup>2</sup>
              </>
            ) : (
              <>{project.value}</>
            )}
          </p>
        </span>
      </div>
      {index !== projectDetails.length - 1 && (
        <div className="landing-page__content__basic-info__line"></div>
      )}
    </>
  );
};
