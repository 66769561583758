import * as React from "react";
import { useTranslation } from "react-i18next";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import MenuItem from "@mui/material/MenuItem";
import styled from "@emotion/styled";
import sakLogo3X from "../../assets/SakLogo3X.png";
import { Drawer, Typography, useMediaQuery, useTheme } from "@mui/material";
import { textStyle } from "../../shared/styles";
import { useLanguageTransition } from "../../LanguageTransitionContext";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { ReactComponent as NewDownloadIcon } from "../../assets/downloadIconSVG.svg";
import { ReactComponent as NewLangIcon } from "../../assets/langauge.svg";
import { ReactComponent as NewHamburgerIcon } from "../../assets/nav-MenuSVG.svg";
import { ReactComponent as NewXIcon } from "../../assets/nav-MenuXSVG.svg";

type ArabicToSectionIdMapType = { [key: string]: string };

// Styled Download Button
const DownloadButton = styled(Button)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  padding: "12px 16px",
  gap: "8px",
  minWidth: "151px",
  height: "45px",
  background: "#EBDD36",
  boxShadow: "0px 20px 30px rgba(25, 28, 50, 0.05)",
  borderRadius: "12px",
  color: "#000000",
  ...textStyle,
  fontWeight: 700,
  fontSize: "14px",
  lineHeight: "150%",
  textTransform: "none",
  marginRight: "10px",
  whiteSpace: "nowrap",
  "& .MuiButton-startIcon": {
    width: "21px",
    height: "21px",
    marginLeft: "-8px",
  },
  "&:hover": {
    backgroundColor: "#d4c02a",
  },
});

// Styled Language Button
const LanguageButton = styled(Button)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  padding: "4px 8px",
  gap: "4px",
  background: "#FFFFFF",
  border: "1px solid #F0F0EF",
  borderRadius: "12px",
  color: "#1C1B13",
  "& .MuiButton-startIcon": {
    width: "16px",
    height: "16px",
  },
  ...textStyle,
  fontWeight: 500,
  fontSize: "14px",
  lineHeight: "150%",
  marginRight: "8px",
});

const InnerNavBar = ({ isTransparent = false }: any) => {
  const { t, i18n } = useTranslation();
  const [selectedTab, setSelectedTab] = React.useState<string>(t("home"));
  const isRtl = i18n.language === "ar"; // Check if language is Arabic
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isIpad = useMediaQuery("(min-width:768px) and (max-width:1200px)");

  const location = useLocation(); // React Router hook to get location info
  const { isTransitioning } = useLanguageTransition();
  const [opacity, setOpacity] = React.useState(1);
  const isTablet = useMediaQuery("(max-width: 1024px)"); // iPad size

  React.useEffect(() => {
    const currentPath = location.pathname.split("/").pop() || "home";

    type PathToTabMapType = {
      home: string;
      "about-sak": string;
      features: string;
      "how-it-works": string;
      "contact-us": string;
      "download-app": string;
    };

    const pathToTabMap: PathToTabMapType = {
      home: t("home"),
      "about-sak": t("aboutSakInner"),
      features: t("features"),
      "how-it-works": t("howItWorks"),
      "contact-us": t("contactUs"),
      "download-app": t("downloadApp"),
    };
    const tabKey = currentPath as keyof PathToTabMapType;

    setSelectedTab(pathToTabMap[tabKey]);
  }, [location, t]);

  const navigate = useNavigate();
  const { setIsTransitioning } = useLanguageTransition();

  const changeLanguage = (language: string) => {
    setIsTransitioning(true); // Start the transition
    setTimeout(() => {
      i18n.changeLanguage(language);
      setIsTransitioning(false); // End the transition
    }, 500); // Duration of transition
  };

  const menuItemStyle: React.CSSProperties = {
    ...textStyle,
    fontWeight: 400,
    fontSize: "16px",
    textTransform: "capitalize",
    cursor: "pointer",
  };

  const [isMobileMenuOpen, setIsMobileMenuOpen] = React.useState(false);
  const [showNavBar, setShowNavBar] = React.useState(true);

  let lastScrollY = window.scrollY;

  const handleScroll = () => {
    // Set background color based on scroll position
    const currentScrollY = window.scrollY;

    // Check the direction of the scroll
    if (currentScrollY < lastScrollY) {
      setShowNavBar(true);
    } else if (currentScrollY > lastScrollY && currentScrollY > 50) {
      setShowNavBar(false);
    }
    // Update the last scroll position
    lastScrollY = currentScrollY;

    const newOpacity = Math.max(0.9, 1 - window.scrollY / 300);
    setOpacity(newOpacity);
  };

  React.useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleNavigation = (tabName: string) => {
    let sectionId;

    // Mapping of Arabic tab names to section IDs
    const arabicToSectionIdMap: ArabicToSectionIdMapType = {
      الرئيسية: "home",
      "عن صك": "about-sak",
      المميزات: "features",
      "كيف يعمل صك؟": "how-it-works?",
      "تواصل معنا": "contact-us",
      "تحميل التطبيق": "download-app",
      "download-app": "download-app",
    };

    if (i18n.language === "ar") {
      sectionId = arabicToSectionIdMap[tabName];
    } else {
      sectionId = tabName.toLowerCase().replace(/\s+/g, "-");
    }

    if (
      sectionId === "home" ||
      sectionId === "download-app" ||
      sectionId === "how-it-works?" ||
      sectionId === "features"
    ) {
      navigate(`/`);
    } else {
      navigate(`/${sectionId}`);
    }
  };
  // Tabs for desktop and mobile views
  const tabs = [
    t("home"),
    t("aboutSakInner"),
    t("features"),
    t("howItWorks"),
    t("contactUs"),
  ];

  // Mobile Menu
  const mobileMenuId = "primary-search-account-menu-mobile";
  const handleCloseMobileMenu = () => {
    setIsMobileMenuOpen(false);
  };

  const handleOpenMobileMenu = () => {
    setIsMobileMenuOpen(true);
  };
  const renderMobileMenu = (
    <Drawer
      anchor="top"
      keepMounted
      open={isMobileMenuOpen}
      onClose={handleCloseMobileMenu}
      sx={{
        zIndex: 1000,
      }}
      transitionDuration={500} // Set the duration for the slide effect
      PaperProps={{
        style: {
          direction: isRtl ? "rtl" : "ltr", // Add direction based on the language
          boxShadow: "none", // Remove any shadow
          borderRadius: "0px", // Make sure there are no rounded corners
          padding: isRtl ? "100px 0 10px 20px" : "100px 20px 10px 10px", // Remove any padding
          paddingTop: "80px", // Add padding to the top to account for the AppBar
          overflowX: "hidden", // Add this line to hide the horizontal scrollbar
        },
      }}
    >
      {tabs.map((tab) => {
        return (
          <MenuItem
            key={tab}
            style={{
              display: "block",
              ...textStyle,
              fontWeight: tab === selectedTab ? 700 : 400,
              fontSize: tab === selectedTab ? "18px" : "16px",
              color: tab === selectedTab ? "#1C1B13" : "#807F7B",
              position: "relative", // Necessary for positioning the :after pseudo-element
              transition: "all 0.3s ease",
              marginRight: "20px",
              // Adjust this to control the length of the border
              borderBottom: tab === selectedTab ? "2px solid #000000" : "none",
              cursor: "pointer",
            }}
            onClick={() => handleNavigation(tab)}
          >
            <Typography
              className={isRtl ? "rtl-text" : ""}
              variant="body1"
              style={menuItemStyle}
            >
              {tab}
            </Typography>
          </MenuItem>
        );
      })}
      {/* <LanguageButton
        startIcon={<NewLangIcon />}
        sx={{
          gap: "16px",
          alignItems: "center",
          justifyContent: "center",
          width: "100%", // Set width to 100% of the parent container
          minHeight: "45px",
          margin: "10px 0",
        }}
        onClick={() => {
          changeLanguage(i18n.language === "en" ? "ar" : "en");
          handleCloseMobileMenu();
        }}
      >
        <div style={{ marginRight: isRtl ? "10px" : "0" }}>
          {i18n.language === "en" ? "العربية" : "English"}
        </div>
      </LanguageButton> */}
      <DownloadButton
        sx={{
          gap: "16px",
          alignItems: "center",
          justifyContent: "center",
          width: "100%", // Set width to 100% of the parent container
          minHeight: "45px",
          margin: "10px 0",
        }}
        startIcon={<NewDownloadIcon />} // Icon on the left of the text for Arabic
        onClick={() => {
          handleNavigation("download-app");
          handleCloseMobileMenu();
        }}
      >
        <div style={{ marginRight: isRtl ? "10px" : "0" }}>
          {t("downloadApp")}
        </div>{" "}
      </DownloadButton>
    </Drawer>
  );

  return (
    <AppBar
      position="fixed"
      color="default"
      elevation={0}
      style={{
        backgroundColor: isMobileMenuOpen ? "#FFF" : "transparent",
        direction: i18n.language === "ar" ? "rtl" : "ltr", // Add direction based on language
        transition: "opacity 0.5s ease, transform 0.5s ease",
        opacity: isTransitioning ? 0 : 1,
        transform: `translateY(${showNavBar ? "0" : "-100%"})`, // Hide or show based on showNavBar
      }}
    >
      <Container maxWidth="lg">
        <Toolbar
          disableGutters
          sx={{
            display: "flex",
            justifyContent: "space-between",
            padding: " 0 10px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent:
                isMobile || isIpad || isTablet ? "space-between" : "flex-start", // This creates space between the child elements
              alignItems: "center", // This vertically centers the child elements
              width: isMobile || isIpad || isTablet ? "100%" : "auto", // Ensure the box takes full width
            }}
          >
            <img
              src={sakLogo3X}
              alt="Logo"
              style={{
                height: "50px",
                borderRadius: "5px",
                margin:
                  isMobile || isIpad || isTablet
                    ? "0"
                    : isRtl
                    ? "0 0 0 20px"
                    : "0",
              }}
              onClick={() => navigate("/")}
            />

            {isMobile || isIpad || isTablet ? (
              <IconButton
                size="large"
                aria-label="show more"
                aria-controls={mobileMenuId}
                aria-haspopup="true"
                color="inherit"
              >
                {isMobileMenuOpen ? (
                  <NewXIcon
                    style={{
                      height: "50px",
                      width: "50px",
                      padding: "5px",
                    }}
                    onClick={handleCloseMobileMenu}
                  />
                ) : (
                  <NewHamburgerIcon
                    style={{
                      height: "50px",
                      width: "50px",
                      padding: "5px",
                    }}
                    onClick={handleOpenMobileMenu}
                  />
                )}
              </IconButton>
            ) : null}
          </Box>
          <Box
            sx={{
              flexGrow: 1,
              display: isMobile || isIpad || isTablet ? "none" : "flex",
              ml: 5,
            }}
          >
            {" "}
            {tabs.map((tab) => (
              <Box
                key={tab}
                onClick={() => {
                  setSelectedTab(tab);
                  handleNavigation(tab);
                }}
                sx={{
                  m: 2,
                  display: "block",
                  ...textStyle,
                  fontWeight: tab === selectedTab ? 700 : 400,
                  fontSize: tab === selectedTab ? "18px" : "16px",
                  color: tab === selectedTab ? "#1C1B13" : "#807F7B",
                  position: "relative", // Necessary for positioning the :after pseudo-element
                  transition: "all 0.3s ease",
                  "&:after": {
                    content: '""',
                    position: "absolute",
                    bottom: 0,
                    left: "50%",
                    transform: "translateX(-50%)",
                    width: "100%", // Adjust this to control the length of the border
                    borderBottom:
                      tab === selectedTab ? "2px solid #000000" : "none",
                    cursor: "pointer",
                  },
                }}
              >
                <Typography
                  className={isRtl ? "rtl-text" : ""}
                  variant="body1"
                  paddingBottom={0.5}
                  marginRight={1}
                  marginLeft={1}
                  style={menuItemStyle}
                >
                  {tab}
                </Typography>{" "}
              </Box>
            ))}
          </Box>

          {/* Render mobile menu */}
          {renderMobileMenu}

          {/* Other Toolbar Elements */}
          <Box sx={{ display: "flex", alignItems: "center" }}>
            {isMobile || isIpad || isTablet ? null : (
              <>
                {/* <LanguageButton
                  startIcon={<NewLangIcon />}
                  sx={{
                    gap: "16px",
                    alignItems: "center",
                    justifyContent: "center",
                    minWidth: "120px",
                    minHeight: "45px",
                    margin: isMobile
                      ? "0"
                      : isRtl
                      ? "0 0 0 20px"
                      : "0 20px 0 0",
                  }}
                  onClick={() =>
                    changeLanguage(i18n.language === "en" ? "ar" : "en")
                  }
                >
                  <div style={{ marginRight: isRtl ? "10px" : "0" }}>
                    {i18n.language === "en" ? "العربية" : "English"}
                  </div>{" "}
                </LanguageButton> */}
                <DownloadButton
                  sx={{
                    gap: "16px",
                    alignItems: "center",
                    justifyContent:
                      isMobile || isIpad || isTablet
                        ? "center"
                        : isRtl
                        ? "flex-start"
                        : "flex-start",
                  }}
                  startIcon={<NewDownloadIcon />} // Icon on the left of the text for Arabic
                  onClick={() => handleNavigation("download-app")}
                >
                  <div style={{ marginRight: isRtl ? "10px" : "0" }}>
                    {t("downloadApp")}
                  </div>{" "}
                </DownloadButton>
              </>
            )}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default InnerNavBar;
