// Import statements
import {
  Box,
  Typography,
  Container,
  Grid,
  Paper,
  Button,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import aboutMobile3XEn from "../../../assets/AboutMobile3XEn.png";
import aboutMobile3XAR from "../../../assets/LastAboutPage3X.png";
import { useNavigate } from "react-router-dom";
import { textStyle, textStyleRegular } from "../../../shared/styles";
import { useTranslation } from "react-i18next";
import { useLanguageTransition } from "../../../LanguageTransitionContext";
import { ReactComponent as NewRightArrowIcon } from "../../../assets/right-iconSVG.svg";
import React from "react";

// Component definition
const AboutSectionComponent = ({ id }: any) => {
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isRtl = i18n.language === "ar"; // Check if language is Arabic
  const navigate = useNavigate();
  const { isTransitioning } = useLanguageTransition();
  const isIpad = useMediaQuery("(min-width:768px) and (max-width:1200px)");

  const redirectToAboutPage = () => navigate("/about-sak");

  React.useEffect(() => {
    // Set the dir attribute based on the current language
    // document.documentElement.setAttribute("dir", i18n.dir(i18n.language));
  }, [i18n.language]);

  return (
    <div
      id={id}
      style={{
        transition: "opacity 0.5s ease, transform 0.5s ease",
        opacity: isTransitioning ? 0 : 1,
        transform: isTransitioning
          ? `translateX(${isTransitioning ? "-30px" : "30px"})`
          : "translateX(0)",
      }}
    >
      <Paper elevation={0} sx={{ py: 4, margin: "20px 0" }}>
        <Container maxWidth="lg">
          <Grid container spacing={3} direction={isRtl ? "row" : "row-reverse"}>
            <Grid
              item
              xs={isIpad ? 6 : 12}
              md={5}
              order={{ xs: isIpad ? 1 : 2, md: isRtl ? 1 : 2 }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  "& img": {
                    width: { xs: isIpad ? "80%" : "100%", md: "80%" },
                    height: { xs: isIpad ? "80%" : "100%", md: "80%" },
                  },
                }}
              >
                <img
                  src={isRtl ? aboutMobile3XAR : aboutMobile3XEn}
                  alt="Mobile"
                />
              </Box>
            </Grid>
            <Grid
              item
              xs={isIpad ? 6 : 12}
              md={4}
              order={isRtl ? 1 : 2}
              sx={{
                margin: { xs: "5% 0", md: "8% 0" },
                marginRight: { xs: "0", md: isRtl ? "20%" : "0" },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: {
                    xs: isIpad ? (isRtl ? "flex-start" : "flex-end") : "center",
                    md: isRtl ? "flex-start" : "flex-end",
                  },
                  justifyContent: "flex-start",
                  height: "100%",
                  textAlign: isRtl ? "right" : "left",
                  minWidth: { xs: "0", md: "500px" },
                  padding: { xs: "0 10px", md: "0" },
                  width: "100%",
                  marginTop: { xs: "0", md: "100px" },
                }}
              >
                <Typography
                  className={isRtl ? "rtl-text" : ""}
                  variant="h4"
                  sx={{
                    ...textStyle,
                    fontWeight: 700, // Bold
                    fontSize: "18px", // Font size
                    color: "#4D4E99", // Secondary/dark color
                    marginBottom: "20px",
                  }}
                  align={isRtl ? "right" : "left"}
                >
                  {t("aboutSakTitle")}
                </Typography>
                <Typography
                  className={isRtl ? "rtl-text" : ""}
                  variant="h4"
                  sx={{
                    ...textStyle,
                    fontWeight: 700, // Bold
                    fontSize: "32px", // Font size
                    color: "#1C1B13", // Basic/Main Text color
                    marginBottom: "20px",
                  }}
                >
                  {t("ourStoryYourFuture")}
                </Typography>

                <Typography
                  className={isRtl ? "rtl-text" : ""}
                  variant="body1"
                  sx={{
                    ...textStyleRegular,
                    fontSize: "16px", // Font size
                    color: "#1C1B13", // Basic/Main Text color
                    textAlign: isRtl ? "right" : "left", // Start from right for RTL languages
                    margin: isMobile ? "20px 0 " : "0px",
                  }}
                >
                  {t("aboutSakDescription")}
                </Typography>
                <Button
                  variant="outlined"
                  sx={{
                    boxSizing: "border-box",
                    display: "flex",
                    flexDirection: isRtl ?"row-reverse": "row" ,
                    justifyContent: "center",
                    alignItems: "center",
                    border: "1px solid #6263AB",
                    filter: "drop-shadow(0px 20px 30px rgba(25, 28, 50, 0.05))",
                    borderRadius: "12px",
                    fontSize: "14px",
                    textAlign: "center",
                    color: "#6263AB",
                    marginTop: isMobile ? "16px" : "20px", // Adjusted margin top for mobile
                    textTransform: "capitalize",
                    width: isMobile ? "100%" : "auto",
                    padding: isMobile ? "8px " : " 8px 10%",
                    fontWeight: 700,
                    "&:hover": {
                      backgroundColor: "#F7F7FF", // Replace with your desired background color
                      borderColor: "#6263AB",
                    },
                    ...textStyle,
                  }}
                  onClick={redirectToAboutPage}
                  startIcon={
                    isRtl ? (
                      <NewRightArrowIcon
                        style={{ transform: "rotate(180deg)" }}
                      />
                    ) : null
                  } // Icon on the right for LTR
                  endIcon={isRtl ? null : <NewRightArrowIcon />}
                >
                  {t("readMore")}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Paper>
    </div>
  );
};

export default AboutSectionComponent;
