import React from "react";
import RefreshIcon from "@mui/icons-material/Refresh";
import internalError from "../../assets/internalError.png";
import ErrorComponent from "../../components/Error/Error";
import { useTranslation } from "react-i18next";

const InternalErrorComponent: React.FC = () => {
  const { t } = useTranslation();
  return (
    <ErrorComponent
      title={t("internalErrorTitle")}
      description={t("internalErrorDescription")}
      buttonTitle={t("internalErrorButtonText")}
      buttonIcon={<RefreshIcon style={{ width: "21px", height: "21px" }} />}
      image={internalError}
      navigateTo={""}
    />
  );
};

export default InternalErrorComponent;
