import {
  useMediaQuery,
  useTheme,
  Container,
  Paper,
  Typography,
  Box,
} from "@mui/material";
import Link from "@mui/material/Link";
import { textStyle, textStyleRegular } from "../../shared/styles";
import { useTranslation } from "react-i18next";
import { useLanguageTransition } from "../../LanguageTransitionContext";
import React from "react";

const PrivacyAndPolicyComponent = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { t, i18n } = useTranslation();
  const isRtl = i18n.language === "ar";
  const { isTransitioning } = useLanguageTransition();

  React.useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <div
      style={{
        transition: "opacity 0.5s ease, transform 0.5s ease",
        opacity: isTransitioning ? 0 : 1,
        transform: isTransitioning
          ? `translateX(${isTransitioning ? "-30px" : "30px"})`
          : "translateX(0)",
        marginTop: "150px",
      }}
    >
      <Container maxWidth="lg">
        <Paper
          elevation={0}
          sx={{
            py: 4,
            overflow: "hidden",
            direction: isRtl ? "rtl" : "ltr", // Set direction based on language
          }}
        >
          <Paper elevation={0} sx={{ py: 4 }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: isMobile ? "center" : "flex-start",
                justifyContent: isMobile ? "center" : "flex-start",
                textAlign: "flex-start",
                height: "100%",
                width: "100%",
              }}
            >
              <Typography
                className={isRtl ? "rtl-text" : ""}
                variant="h4"
                sx={{
                  ...textStyle,
                  width: "95%",
                  fontWeight: 700, // Bold
                  fontSize: "18px", // Font size
                  marginBottom: "10px",
                }}
              >
                {t("introduction")}
              </Typography>

              <Typography
                className={isRtl ? "rtl-text" : ""}
                variant="body1"
                sx={{
                  ...textStyleRegular,
                  width: "95%",
                  marginBottom: "20px",
                }}
              >
                {t("introductionText")}
              </Typography>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: isMobile ? "center" : "flex-start",
                justifyContent: isMobile ? "center" : "flex-start",
                textAlign: "flex-start",
                height: "100%",
                width: "100%",
              }}
            >
              <Typography
                className={isRtl ? "rtl-text" : ""}
                variant="h4"
                sx={{
                  ...textStyle,
                  width: "95%",
                  fontWeight: 700, // Bold
                  fontSize: "18px", // Font size
                  marginBottom: "10px",
                }}
              >
                {t("dataCollection")}
              </Typography>

              <Typography
                className={isRtl ? "rtl-text" : ""}
                variant="body1"
                sx={{
                  ...textStyleRegular,
                  width: "95%",
                  marginBottom: "20px",
                }}
              >
                {t("dataCollectionText")}
                <ul>
                  <li>
                    <Typography
                      className={isRtl ? "rtl-text" : ""}
                      sx={{ ...textStyleRegular, width: "95%" }}
                    >
                      {t("dataCollectionSub1")}
                    </Typography>
                  </li>
                  <li>
                    <Typography
                      className={isRtl ? "rtl-text" : ""}
                      sx={{ ...textStyleRegular, width: "95%" }}
                    >
                      {t("dataCollectionSub2")}
                    </Typography>
                  </li>
                  <li>
                    <Typography
                      className={isRtl ? "rtl-text" : ""}
                      sx={{ ...textStyleRegular, width: "95%" }}
                    >
                      {t("dataCollectionSub3")}
                    </Typography>
                  </li>
                  <li>
                    <Typography
                      className={isRtl ? "rtl-text" : ""}
                      sx={{ ...textStyleRegular, width: "95%" }}
                    >
                      {t("dataCollectionSub4")}
                    </Typography>
                  </li>
                </ul>
              </Typography>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: isMobile ? "center" : "flex-start",
                justifyContent: isMobile ? "center" : "flex-start",
                textAlign: "flex-start",
                height: "100%",
                width: "100%",
              }}
            >
              <Typography
                className={isRtl ? "rtl-text" : ""}
                variant="h4"
                sx={{
                  ...textStyle,
                  width: "95%",

                  fontWeight: 700, // Bold
                  fontSize: "18px", // Font size
                  marginBottom: "10px",
                }}
              >
                {t("useOfInformation")}
              </Typography>

              <Typography
                className={isRtl ? "rtl-text" : ""}
                variant="body1"
                sx={{
                  ...textStyleRegular,
                  width: "95%",
                  marginBottom: "20px",
                }}
              >
                {t("useOfInformationText")}
                <ul>
                  <li>
                    <Typography
                      className={isRtl ? "rtl-text" : ""}
                      sx={{ ...textStyleRegular, width: "95%" }}
                    >
                      {t("useOfInformationSub1")}
                    </Typography>
                  </li>
                  <li>
                    <Typography
                      className={isRtl ? "rtl-text" : ""}
                      sx={{ ...textStyleRegular, width: "95%" }}
                    >
                      {t("useOfInformationSub2")}
                    </Typography>
                  </li>
                  <li>
                    <Typography
                      className={isRtl ? "rtl-text" : ""}
                      sx={{ ...textStyleRegular, width: "95%" }}
                    >
                      {t("useOfInformationSub3")}
                    </Typography>
                  </li>
                  <li>
                    <Typography
                      className={isRtl ? "rtl-text" : ""}
                      sx={{ ...textStyleRegular, width: "95%" }}
                    >
                      {t("useOfInformationSub4")}
                    </Typography>
                  </li>

                  <li>
                    <Typography
                      className={isRtl ? "rtl-text" : ""}
                      sx={{ ...textStyleRegular, width: "95%" }}
                    >
                      {t("useOfInformationSub5")}
                    </Typography>
                  </li>
                  <li>
                    <Typography
                      className={isRtl ? "rtl-text" : ""}
                      sx={{ ...textStyleRegular, width: "95%" }}
                    >
                      {t("useOfInformationSub6")}
                    </Typography>
                  </li>
                </ul>
              </Typography>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: isMobile ? "center" : "flex-start",
                justifyContent: isMobile ? "center" : "flex-start",
                textAlign: "flex-start",
                height: "100%",
                width: "100%",
              }}
            >
              <Typography
                className={isRtl ? "rtl-text" : ""}
                variant="h4"
                sx={{
                  ...textStyle,
                  fontWeight: 700, // Bold
                  fontSize: "18px", // Font size
                  marginBottom: "10px",
                }}
              >
                {t("Disclosure")}{" "}
              </Typography>

              <Typography
                className={isRtl ? "rtl-text" : ""}
                variant="body1"
                sx={{
                  ...textStyleRegular,
                  width: "95%",
                  fontWeight: 400, // Heading/Regular/18px
                  fontSize: "18px", // Font size
                  color: "#1C1B13", // Basic/Main Text color
                  marginBottom: "20px",
                }}
              >
                <ul>
                  <li style={{ marginBottom: "40px" }}>
                    <Typography
                      className={isRtl ? "rtl-text" : ""}
                      sx={{ ...textStyleRegular, width: "95%" }}
                    >
                      {t("DisclosureSub1")}{" "}
                    </Typography>
                  </li>
                  <li>
                    <Typography
                      className={isRtl ? "rtl-text" : ""}
                      sx={{ ...textStyleRegular, width: "95%" }}
                    >
                      {t("DisclosureSub2")}{" "}
                    </Typography>
                  </li>
                </ul>
              </Typography>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: isMobile ? "center" : "flex-start",
                justifyContent: isMobile ? "center" : "flex-start",
                textAlign: "flex-start",
                height: "100%",
                width: "100%",
              }}
            >
              <Typography
                className={isRtl ? "rtl-text" : ""}
                variant="h4"
                sx={{
                  ...textStyle,
                  width: "95%",
                  fontWeight: 700, // Bold
                  fontSize: "18px", // Font size
                  marginBottom: "10px",
                }}
              >
                {t("AccessText")}{" "}
              </Typography>

              <Typography
                className={isRtl ? "rtl-text" : ""}
                variant="body1"
                sx={{
                  ...textStyleRegular,
                  width: "95%",
                  marginBottom: "20px",
                }}
              >
                {t("AccessSubText")}{" "}
                <ol>
                  <li>
                    <Typography
                      className={isRtl ? "rtl-text" : ""}
                      sx={{
                        ...textStyle,
                        fontWeight: "bold",
                        marginBottom: "10px",
                      }}
                    >
                      {t("ComplianceText")}{" "}
                    </Typography>
                  </li>
                  <Typography
                    className={isRtl ? "rtl-text" : ""}
                    sx={{
                      ...textStyleRegular,
                      marginBottom: "10px",
                      width: "95%",
                    }}
                  >
                    {t("ComplianceSubText")}{" "}
                  </Typography>
                  <li>
                    <Typography
                      className={isRtl ? "rtl-text" : ""}
                      sx={{
                        ...textStyle,
                        width: "95%",
                        fontWeight: "bold",
                        marginBottom: "10px",
                      }}
                    >
                      {t("SecurityText")}{" "}
                    </Typography>
                  </li>

                  <Typography
                    className={isRtl ? "rtl-text" : ""}
                    sx={{
                      ...textStyleRegular,
                      marginBottom: "10px",
                      width: "95%",
                    }}
                  >
                    {t("SecuritySubText")}{" "}
                  </Typography>
                  <li>
                    <Typography
                      className={isRtl ? "rtl-text" : ""}
                      sx={{
                        ...textStyle,
                        fontWeight: "bold",
                        marginBottom: "10px",
                        width: "95%",
                      }}
                    >
                      {t("ConfidentialityText")}{" "}
                    </Typography>
                  </li>
                  <ul>
                    <li>
                      <Typography
                        className={isRtl ? "rtl-text" : ""}
                        sx={{
                          ...textStyleRegular,
                          marginBottom: "10px",
                          width: "95%",
                        }}
                      >
                        {t("ConfidentialitySub1Text")}{" "}
                      </Typography>
                    </li>
                    <li>
                      <Typography
                        className={isRtl ? "rtl-text" : ""}
                        sx={{
                          ...textStyleRegular,
                          marginBottom: "10px",
                          width: "95%",
                        }}
                      >
                        {t("ConfidentialitySub2Text")}{" "}
                      </Typography>
                    </li>
                    <li>
                      <Typography
                        className={isRtl ? "rtl-text" : ""}
                        sx={{
                          ...textStyleRegular,
                          marginBottom: "10px",
                          width: "95%",
                        }}
                      >
                        {t("ConfidentialitySub3Text")}{" "}
                      </Typography>
                    </li>
                  </ul>
                </ol>
              </Typography>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: isMobile ? "center" : "flex-start",
                justifyContent: isMobile ? "center" : "flex-start",
                textAlign: "flex-start",
                height: "100%",
                width: "100%",
              }}
            >
              <Typography
                className={isRtl ? "rtl-text" : ""}
                variant="h4"
                sx={{
                  ...textStyle,
                  width: "95%",
                  fontWeight: 700, // Bold
                  fontSize: "18px", // Font size
                  marginBottom: "10px",
                }}
              >
                {t("ChangePrivacyText")}{" "}
              </Typography>

              <Typography
                className={isRtl ? "rtl-text" : ""}
                variant="body1"
                sx={{
                  ...textStyleRegular,
                  width: "95%",
                  marginBottom: "20px",
                }}
              >
                {t("ChangePrivacySubText")}{" "}
              </Typography>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: isMobile ? "center" : "flex-start",
                justifyContent: isMobile ? "center" : "flex-start",
                textAlign: "flex-start",
                height: "100%",
                width: "100%",
              }}
            >
              <Typography
                className={isRtl ? "rtl-text" : ""}
                variant="h4"
                sx={{
                  ...textStyle,
                  width: "95%",
                  fontWeight: 700, // Bold
                  fontSize: "18px", // Font size
                  marginBottom: "10px",
                }}
              >
                {t("CallUsText")}{" "}
              </Typography>

              <Typography
                className={isRtl ? "rtl-text" : ""}
                variant="body1"
                sx={{
                  ...textStyleRegular,
                  width: "95%",
                  marginBottom: "20px",
                }}
              >
                {t("CallUsTextSub1")}{" "}
                <Link
                  href="mailto:support@getsak.com"
                  target="_blank"
                  sx={{ textDecoration: "none" }}
                >
                  {t("CallUsTextSub2")}{" "}
                </Link>
              </Typography>
            </Box>
          </Paper>
        </Paper>
      </Container>
    </div>
  );
};

export default PrivacyAndPolicyComponent;
