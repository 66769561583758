import React from "react";
import HomeIcon from "@mui/icons-material/Home"; // Importing Home icon for button
import notFound from "../../assets/notFound.png";
import ErrorComponent from "../../components/Error/Error";
import { useTranslation } from "react-i18next";

const ForbiddenErrorComponent: React.FC = () => {
  const { t } = useTranslation();

  return (
    <ErrorComponent
      title={t("forbiddenErrorTitle")}
      description={t("forbiddenErrorDescription")}
      buttonTitle={t("forbiddenErrorButtonText")}
      buttonIcon={<HomeIcon style={{ width: "21px", height: "21px" }} />}
      image={notFound}
      navigateTo={"/"}
    />
  );
};

export default ForbiddenErrorComponent;
