import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

import { textStyle } from "../../shared/styles";
import { useNavigate } from "react-router-dom";
import { useLanguageTransition } from "../../LanguageTransitionContext";
import { useTranslation } from "react-i18next";
import React from "react";

const ErrorComponent = ({
  title,
  description,
  buttonTitle,
  buttonIcon,
  image,
  navigateTo,
}: any) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { isTransitioning } = useLanguageTransition();
  const { i18n } = useTranslation();
  const isRtl = i18n.language === "ar";

  const iconProps = isRtl ? { endIcon: buttonIcon } : { startIcon: buttonIcon };

  React.useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  // Adjust styles based on screen size
  const containerStyles = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "20px",
    transition: "opacity 0.5s ease, transform 0.5s ease",
    opacity: isTransitioning ? 0 : 1,
    transform: isTransitioning
      ? `translateX(${isTransitioning ? "-30px" : "30px"})`
      : "translateX(0)",
    marginTop: "160px",
  };

  const buttonStyles = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    padding: isMobile ? "12px 15%" : "12px 30px",
    gap: "8px",
    background: "#EBDD36",
    borderRadius: "14px",
    color: "#000000", // Text color
    fontSize: "14px",
    textTransform: "capitalize",
    ...textStyle,
    "&:hover": {
      backgroundColor: "#d4c02a",
    },

    // Font size for the text inside the button
  };

  return (
    <Container sx={containerStyles}>
      <img
        src={image}
        alt="Error"
        style={{ maxWidth: isMobile ? "80%" : "100%", height: "auto" }}
      />
      <Typography
        className={isRtl ? "rtl-text" : ""}
        variant="h4"
        gutterBottom
        style={{
          color: "#1C1B13",
          ...textStyle,
          marginTop: isMobile ? "20px" : "50px",
          fontWeight: "bold",
          fontSize: isMobile ? "24px" : "34px",
        }}
      >
        {title}{" "}
      </Typography>
      <Typography
        className={isRtl ? "rtl-text" : ""}
        variant="subtitle1"
        style={{
          color: "#1C1B13",
          fontSize: isMobile ? "16px" : "20px",
          ...textStyle,
        }}
      >
        {description}{" "}
      </Typography>

      <Button
        sx={buttonStyles}
        {...iconProps}
        onClick={() => {
          navigate(`${navigateTo}`);
        }}
      >
        {buttonTitle}{" "}
      </Button>
    </Container>
  );
};

export default ErrorComponent;
