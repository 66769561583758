import React from "react";
import { Box, Typography, useTheme, useMediaQuery } from "@mui/material";
import { StepItem } from "./StepItem";

import { ReactComponent as NewHowItWorks1Icon } from "../../../assets/howSVG1.svg";
import { ReactComponent as NewHowItWorks2Icon } from "../../../assets/howSVG2.svg";
import { ReactComponent as NewHowItWorks3Icon } from "../../../assets/howSVG3.svg";
import { ReactComponent as NewHowItWorks4Icon } from "../../../assets/howSVG4.svg";

import { textStyle } from "../../../shared/styles";
import { useTranslation } from "react-i18next";

export const ContentSection: React.FC = () => {
  const { t, i18n } = useTranslation();
  const isRtl = i18n.language === "ar";
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const steps = [
    {
      icon: <NewHowItWorks1Icon />,
      title: t("choosePropertyTitle"),
      description: t("choosePropertyDesc"),
    },
    {
      icon: <NewHowItWorks2Icon />,
      title: t("verifyInfoTitle"),
      description: t("verifyInfoDesc"),
    },
    {
      icon: <NewHowItWorks3Icon />,
      title: t("completePaymentsTitle"),
      description: t("completePaymentsDesc"),
    },
    {
      icon: <NewHowItWorks4Icon />,
      title: t("ownPropertyTitle"),
      description: t("ownPropertyDesc"),
    },
  ];
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        padding: "30px 0 ",
        gap: "32px",
        width: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: isMobile ? "center" : "flex-start",
          gap: "16px",
          width: "100%",
        }}
      >
        <Typography
          className={isRtl ? "rtl-text" : ""}
          variant="subtitle1"
          sx={{
            ...textStyle,
            fontWeight: 700,
            fontSize: "18px",
            lineHeight: "150%",
            color: "#6263AB",
          }}
        >
          {t("howItWorksSubtitle")}{" "}
        </Typography>
        <Typography
          className={isRtl ? "rtl-text" : ""}
          variant="h4"
          sx={{
            ...textStyle,
            fontWeight: 700,
            fontSize: "32px",
            textAlign: isMobile ? "center" : "flex-start",
            lineHeight: "130%",
            color: "#1C1B13",
          }}
        >
          {t("journeyToHomeOwnership")}{" "}
        </Typography>
      </Box>
      {steps.map((step, index) => (
        <StepItem
          key={index}
          icon={step.icon}
          title={step.title}
          description={step.description}
        />
      ))}
    </Box>
  );
};
