import { Button, Container } from "@mui/material";

import ContactFormRadio from "./ContactFormRadio";
import DropdownMenu from "./DropDownMenu";
import MessageBox from "./MessageBox";
import MobileNumberField from "./MobileNumberField";
import NameField from "./NameField";
import { useTranslation } from "react-i18next";
import React, { useEffect } from "react";
import { textStyle } from "../../../shared/styles";
import EmailField from "./EmailField";

const ContactForm = ({ isMobile, isIpad, handleSubmit }: any) => {
  const { t } = useTranslation();
  const [userType, setUserType] = React.useState("");
  const [name, setName] = React.useState("");
  const [mobileNumber, setMobileNumber] = React.useState("");
  const [queryType, setQueryType] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [email, setEmail] = React.useState("");

  const [isFormValid, setIsFormValid] = React.useState(false);

  useEffect(() => {
    const emailRegex = /^\S+@\S+\.\S+$/;

    setIsFormValid(
      name.trim() !== "" &&
        mobileNumber.trim() !== "" &&
        message.trim() !== "" &&
        queryType.trim() !== "" &&
        userType.trim() !== "" &&
        mobileNumber.length === 10 &&
        mobileNumber.startsWith("05") && // Check if mobileNumber starts with 05
        email.trim() !== "" &&
        emailRegex.test(email) // Validate email with regex
    );
  }, [name, mobileNumber, message, queryType, userType, email]);

  // Modified handleSubmit function
  const handleSubmitForm = () => {
    const queryTypeMappings: any = {
      option1: "COMPLAINT",
      option2: "SUGGESTION",
      option3: "SUPPORT",
      option4: "OTHER",
    };
    const data = {
      userType,
      name,
      mobileNumber,
      queryType: queryTypeMappings[queryType], // Map the value here
      message,
      email,
    };
    handleSubmit(data);
  };

  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: isMobile || isIpad ? "center" : "flex-start",
        padding: "20px",
        gap: "10px",
        width: isMobile || isIpad ? "95%" : "90%",
        background: "#FFFFFF",
        borderRadius: "16px",
        boxShadow: "0px 16px 48px rgba(196, 196, 196, 0.2)", // Increased shadow effect
        marginBottom: "70px",
      }}
    >
      <ContactFormRadio
        isMobile={isMobile}
        userType={userType}
        setUserType={setUserType}
      />
      <NameField name={name} setName={setName} />
      <EmailField email={email} setEmail={setEmail} />
      <MobileNumberField
        isMobile={isMobile}
        mobileNumber={mobileNumber}
        setMobileNumber={setMobileNumber}
      />
      <DropdownMenu queryType={queryType} setQueryType={setQueryType} />
      <MessageBox message={message} setMessage={setMessage} />

      <Button
        variant="contained"
        fullWidth
        onClick={handleSubmitForm}
        sx={{
          padding: "12px 16px",
          borderRadius: "14px",
          background: "#6263AB",
          boxShadow: "0px 20px 30px rgba(25, 28, 50, 0.05)",
          "&:hover": {
            background: "#6263AB",
            boxShadow: "0px 20px 30px rgba(25, 28, 50, 0.1)",
          },
          textTransform: "none",
          fontSize: "14px",
          fontWeight: 700,
          lineHeight: "150%",
          color: "#FFFFFF",
          ...textStyle,
        }}
        disabled={!isFormValid}
      >
        {t("sendMessage")}{" "}
      </Button>
    </Container>
  );
};

export default ContactForm;
