import React from "react";
import {
  DialogContent,
  DialogTitle,
  Typography,
  Box,
  Grid,
  useTheme,
  Drawer,
} from "@mui/material";
import { textStyle, textStyleRegular } from "../../shared/styles";

import multiPayment3X from "../../assets/MultiPaymentBottomSheet3X.png";

import { useTranslation } from "react-i18next";
import { StyledButton } from "./StyledButton";

interface BottomSheetProps {
  open: boolean;
  onClose: () => void;
}

const MultiPaymentBottomSheet: React.FC<BottomSheetProps> = ({
  open,
  onClose,
}) => {
  const theme = useTheme();
  const { t, i18n } = useTranslation();
  const isRtl = i18n.language === "ar";

  React.useEffect(() => {
    const img = new Image();
    img.src = multiPayment3X;
  }, []);
  return (
    <Drawer
      anchor="bottom"
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          boxShadow: "0px 4px 10px rgba(200, 200, 200, 0.3)",
          borderRadius: "24px 24px 0 0", // Rounded corners on the top side
          padding: theme.spacing(2),
          minHeight: "30vh", // Adjust the height of the bottom sheet
          maxHeight: "90vh",
          direction: isRtl ? "rtl" : "ltr",
        },
      }}
    >
      <Grid
        container
        justifyContent="center"
        sx={{
          display: "flex",
          flexDirection: "row",
          backgroundColor: "#F7F7FF",
          padding: "10px",
          borderRadius: "20px",
        }}
      >
        <Box>
          {open && (
            <img
              src={multiPayment3X}
              alt="Multi Payment"
              style={{ width: "100%" }}
            />
          )}
        </Box>
      </Grid>
      <DialogTitle
        sx={{
          ...textStyle,
          fontSize: "18px", // Smaller font size on mobile
          lineHeight: "100%",
          color: "#1C1B13",
          textAlign: "center",
        }}
      >
        {t("multiMethodTitle")}{" "}
      </DialogTitle>
      <DialogContent>
        <Typography
          className={isRtl ? "rtl-text" : ""}
          gutterBottom
          sx={{
            ...textStyleRegular,
            color: "#1C1B13",
            textAlign: "center",
          }}
        >
          {t("multiMethodDesc")}{" "}
        </Typography>
        <StyledButton onClick={onClose}>{t("Close")}</StyledButton>{" "}
      </DialogContent>
    </Drawer>
  );
};

export default MultiPaymentBottomSheet;
