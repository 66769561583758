import { Box, Typography, TextField } from "@mui/material";
import { textStyleRegular } from "../../../shared/styles";
import { useTranslation } from "react-i18next";

const MobileNumberField = ({
  isMobile,
  mobileNumber,
  setMobileNumber,
}: any) => {
  const { t, i18n } = useTranslation();
  const isRtl = i18n.language === "ar";
  return (
    <Box
      sx={{
        width: "100%",
        direction: isRtl ? "rtl" : "ltr", // Adjust direction based on language
      }}
    >
      <Typography
        className={isRtl ? "rtl-text" : ""}
        variant="body1"
        sx={{ ...textStyleRegular, mb: 1, color: "#1C1B13" }}
      >
        {t("mobileNumber")}{" "}
      </Typography>
      <TextField
        fullWidth
        type="tel" // Set the type to 'tel' to open the mobile number keyboard
        variant="outlined"
        placeholder={t("mobileNumberPlaceholder")}
        value={mobileNumber}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          const newValue = e.target.value;
          // Check if input is numeric and does not exceed the maximum length (9 digits)
          if (/^\d*$/.test(newValue) && newValue.length <= 10) {
            setMobileNumber(newValue);
          }
        }}
        inputProps={{
          maxLength: 10 ,
          // Note the lowercase 'i' in inputProps
          style: {
            textAlign: isRtl ? "right" : "left",
          },
          dir: isRtl ? "rtl" : "ltr", // Apply the direction attribute directly to the input element
        }}
        // InputProps={{
        //   startAdornment: !isMobile ? (
        //     <InputAdornment position="start">
        //       <Box sx={{ display: "flex", alignItems: "center" }}>
        //         {t("+966")}
        //         <Box
        //           sx={{
        //             height: "28px",
        //             width: "1px",
        //             bgcolor: "divider",
        //             mx: 1,
        //           }}
        //         />
        //       </Box>
        //     </InputAdornment>
        //   ) : null,
        //   style: { paddingLeft: 8 },
        // }}
        sx={{
          background: "#FFFFFF",
          border: "1px solid #F0F0EF",
          boxShadow: "0px 4px 12px rgba(196, 196, 196, 0.1)",
          borderRadius: "8px",
          "& .MuiInputBase-root": {
            ...textStyleRegular,
            fontSize: "14px",
            color: "#1C1B13",
            direction: isRtl ? "rtl" : "ltr", // Apply direction style directly to the input
          },
          "& .MuiOutlinedInput-notchedOutline": {
            border: "none",
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "#B6B6B4",
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#6263AB",
          },
          "& .MuiInputBase-input::placeholder": {
            textAlign: isRtl ? "right" : "left",
          },
        }}
      />
    </Box>
  );
};

export default MobileNumberField;
