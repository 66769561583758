import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

import { textStyle } from "../../shared/styles";
import { useLocation, useNavigate } from "react-router-dom";
import { useLanguageTransition } from "../../LanguageTransitionContext";
import { useTranslation } from "react-i18next";
import HomeIcon from "@mui/icons-material/Home";
import React from "react";
import Lottie from "react-lottie";

import animationData from "../../assets/Animation - success2.json";
import { Box } from "@mui/material";

const defaultOptions = {
  loop: false,
  autoplay: false,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};
const ContactUsSubmittedComponent = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { isTransitioning } = useLanguageTransition();
  const { t, i18n } = useTranslation();
  const isRtl = i18n.language === "ar";

  const iconProps = isRtl
    ? { endIcon: <HomeIcon style={{ width: "21px", height: "21px" }} /> }
    : { startIcon: <HomeIcon style={{ width: "21px", height: "21px" }} /> };

  React.useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  // Adjust styles based on screen size
  const containerStyles = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: isMobile ? "20px" : "20px",
    transition: "opacity 0.5s ease, transform 0.5s ease",
    opacity: isTransitioning ? 0 : 1,
    transform: isTransitioning
      ? `translateX(${isTransitioning ? "-30px" : "30px"})`
      : "translateX(0)",
    marginTop: "140px",
  };

  const buttonStyles = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    padding: isMobile ? "12px 16px" : "12px 30px",
    gap: "8px",
    background: "#EBDD36",
    borderRadius: "14px",
    color: "#000000", // Text color
    fontSize: "14px",
    textTransform: "capitalize",
    ...textStyle,
  };

  React.useEffect(() => {
    const data = location?.state?.data;
    if (!data) {
      navigate("/not-found");
    }
  }, [navigate, location.state]);
  return (
    <Container sx={containerStyles}>
      <Box sx={{ width: "300px" }}>
        <Lottie options={defaultOptions} />
      </Box>

      <Typography
        className={isRtl ? "rtl-text" : ""}
        variant="h4"
        gutterBottom
        style={{
          color: "#1C1B13",
          ...textStyle,
          marginTop: isMobile ? "20px" : "30px",
          fontWeight: "bold",
          fontSize: isMobile ? "24px" : "34px",
        }}
      >
        {t("thanksMessage")}{" "}
      </Typography>
      <Typography
        className={isRtl ? "rtl-text" : ""}
        variant="subtitle1"
        style={{
          color: "#1C1B13",
          fontSize: isMobile ? "16px" : "20px",
          ...textStyle,
          textAlign: "center",
        }}
      >
        {t("thanksMessageDescription")}{" "}
      </Typography>

      <Button
        sx={buttonStyles}
        {...iconProps}
        onClick={() => {
          navigate("/");
        }}
      >
        {t("thanksMessageButtonText")}{" "}
      </Button>
    </Container>
  );
};

export default ContactUsSubmittedComponent;
