import React from "react";
import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  useTheme,
  useMediaQuery,
} from "@mui/material";

import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import styled from "@emotion/styled";
import { textStyleRegular } from "../../../shared/styles";
import { useTranslation } from "react-i18next";

const StyledAddIcon = styled(AddIcon)(({ theme }) => ({
  width: "20px",
  height: "20px",
  color: "#6263AB",
  backgroundColor: "#EBEBFF",
  borderRadius: "50%",
  "&:before": {
    content: '""',
    position: "absolute",
    width: "13.33px",
    height: "13.33px",
    left: "3.33px",
    top: "3.33px",
    border: "2px solid #6263AB",
    borderRadius: "20px",
    backgroundColor: "#EBEBFF",
  },
}));

const StyledRemoveIcon = styled(RemoveIcon)(({ theme }) => ({
  width: "20px",
  height: "20px",
  color: "#6263AB",
  backgroundColor: "#EBEBFF",
  borderRadius: "50%",
  "&:before": {
    content: '""',
    position: "absolute",
    width: "13.33px",
    height: "13.33px",
    left: "3.33px",
    top: "3.33px",
    border: "2px solid #6263AB",
    borderRadius: "20px",
    backgroundColor: "#EBEBFF",
  },
}));

const FAQItem = ({ title, content }: any) => {
  const [expanded, setExpanded] = React.useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const { i18n } = useTranslation();
  const isRtl = i18n.language === "ar"; // Check if language is Arabic

  const handleToggleExpand = () => {
    setExpanded(!expanded);
  };

  return (
    <Accordion
      expanded={expanded}
      onChange={handleToggleExpand}
      sx={{
        flexDirection: isRtl ? "row" : "row-reverse",
        boxSizing: "border-box",
        width: "100%",
        backgroundColor: "#FFFFFF",
        border: "1px solid #F7F7FF",
        boxShadow: "0px 4px 16px rgba(200, 200, 200, 0.1)",
        borderRadius: "20px",
        "&:before": { display: "none" },
        "& .MuiAccordionSummary-content": {
          alignItems: "center",
        },
        "&:first-of-type": {
          borderTopLeftRadius: "20px",
          borderTopRightRadius: "20px",
        },
        "&:last-of-type": {
          borderBottomLeftRadius: "20px",
          borderBottomRightRadius: "20px",
        },
        "&.Mui-expanded": {
          margin: "10px",
        },
        "&:first-of-type.Mui-expanded + &": {
          borderTopLeftRadius: "20px",
          borderTopRightRadius: "20px",
        },
        "&:last-of-type.Mui-expanded + &": {
          borderBottomLeftRadius: "20px",
          borderBottomRightRadius: "20px",
        },
        // marginBottom: "10px",
      }}
    >
      <AccordionSummary
        expandIcon={expanded ? <StyledRemoveIcon /> : <StyledAddIcon />}
        sx={{
          padding: "24px",
          height: "60px",
          "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
            transform: "rotate(180deg)",
          },
          "& .MuiAccordionSummary-content": {
            margin: 0,
          },
          flexDirection: isRtl ? "row" : "row-reverse",
        }}
      >
        <Typography
          className={isRtl ? "rtl-text" : ""}
          sx={{
            fontWeight: 700,
            fontSize: isMobile ? "12px" : "16px",
            lineHeight: "150%",
            color: "#1C1B13",
            width: "100%",
            ...textStyleRegular,
            textAlign: isRtl ? "right" : "left", // Adjust text alignment
          }}
        >
          {title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          position: "relative",
          width: "100%",
          flexDirection: "column",
          alignItems: "flex-start",
          padding: "0px 24px 24px",
          "&::before": {
            content: '""',
            position: "absolute",
            left: isRtl ? "auto" : "10px", // Adjust left position for RTL
            right: isRtl ? "10px" : "auto", // Adjust right position for RTL
            top: 5,
            bottom: 30,
            width: "2px",
            backgroundColor: "#6263AB",
            marginRight: "16px",
          },
        }}
      >
        <Typography
          className={isRtl ? "rtl-text" : ""}
          sx={{
            fontWeight: "400",
            fontSize: isMobile ? "12px" : "16px",
            lineHeight: "150%",
            color: "#1C1B13",
            ...textStyleRegular,
            textAlign: isRtl ? "right" : "left", // Adjust text alignment
            marginRight: isRtl ? "10px" : "0",
          }}
        >
          {content}
        </Typography>
      </AccordionDetails>
    </Accordion>
  );
};

export default FAQItem;
