import { Box, TextField, InputLabel } from "@mui/material";
import { textStyleRegular } from "../../../shared/styles";
import { useTranslation } from "react-i18next";

const MessageBox = ({ message, setMessage }: any) => {
  const { t, i18n } = useTranslation();
  const isRtl = i18n.language === "ar"; // Check if language is Arabic
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "4px",
        width: "100%",
        direction: isRtl ? "rtl" : "ltr", // Adjust direction based on language
      }}
    >
      <InputLabel sx={{ ...textStyleRegular, mb: 1, color: "#1C1B13" }}>
        {t("yourMessage")}{" "}
      </InputLabel>
      <TextField
        fullWidth
        multiline
        rows={4} // Number of rows for the text area
        variant="outlined"
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        placeholder={t("messagePlaceholder")}
        sx={{
          background: "#FFFFFF",
          border: "1px solid #F0F0EF",
          boxShadow: "0px 4px 12px rgba(196, 196, 196, 0.1)",
          borderRadius: "8px",
          "& .MuiInputBase-root": {
            ...textStyleRegular,
            fontSize: "14px",
            color: "#1C1B13",
          },
          "& .MuiOutlinedInput-notchedOutline": {
            border: "none",
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "#B6B6B4",
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#6263AB",
          },
        }}
      />
    </Box>
  );
};

export default MessageBox;
