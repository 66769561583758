import * as React from "react";
import { useTranslation } from "react-i18next";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import MenuItem from "@mui/material/MenuItem";
import styled from "@emotion/styled";
import { Drawer, Typography, useMediaQuery, useTheme } from "@mui/material";
import { textStyle } from "../../shared/styles";
import { useLanguageTransition } from "../../LanguageTransitionContext";
import { ReactComponent as NewDownloadIcon } from "../../assets/downloadIconSVG.svg";
import { ReactComponent as NewLangIcon } from "../../assets/langauge.svg";
import { Link } from "react-scroll";

import sakLogo3X from "../../assets/SakLogo3X.png";
import { ReactComponent as NewHamburgerIcon } from "../../assets/nav-MenuSVG.svg";
import { ReactComponent as NewXIcon } from "../../assets/nav-MenuXSVG.svg";

// Styled Download Button
const DownloadButton = styled(Button)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  padding: "12px 16px",
  gap: "8px",
  minWidth: "151px",
  height: "45px",
  background: "#EBDD36",
  boxShadow: "0px 20px 30px rgba(25, 28, 50, 0.05)",
  borderRadius: "12px",
  color: "#000000",
  ...textStyle,
  fontWeight: 700,
  fontSize: "14px",
  lineHeight: "150%",
  textTransform: "none",
  marginRight: "10px",
  whiteSpace: "nowrap",
  "& .MuiButton-startIcon": {
    width: "21px",
    height: "21px",
    marginLeft: "-8px",
  },
  "&:hover": {
    backgroundColor: "#d4c02a",
  },
});

// Styled Language Button
const LanguageButton = styled(Button)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  padding: "4px 8px",
  gap: "8px",
  background: "#FFFFFF",
  border: "1px solid #F0F0EF",
  borderRadius: "12px",
  color: "#1C1B13",
  ...textStyle,
  fontWeight: 500,
  fontSize: "14px",
  lineHeight: "150%",
  justifyContent: "center",
});

const NavBar = ({ heroSectionRef }: any) => {
  const { t, i18n } = useTranslation();
  const [selectedTab, setSelectedTab] = React.useState<string>(t("home"));
  const [opacity, setOpacity] = React.useState(1);
  const isRtl = i18n.language === "ar"; // Check if language is Arabic
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isIpad = useMediaQuery("(min-width:768px) and (max-width:1200px)");
  const isTablet = useMediaQuery("(max-width: 1024px)"); // iPad size

  const { setIsTransitioning } = useLanguageTransition();
  const { isTransitioning } = useLanguageTransition();

  const changeLanguage = (language: string) => {
    setSelectedTab(isRtl ? "Home" : "الرئيسية");
    setIsTransitioning(true); // Start the transition
    setTimeout(() => {
      i18n.changeLanguage(language);
      setIsTransitioning(false); // End the transition
    }, 500); // Duration of transition
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const [isMobileMenuOpen, setIsMobileMenuOpen] = React.useState(false);

  const handleNavigation = (tabName: string) => {
    let sectionId;

    sectionId = tabName.toLowerCase();

    const section = document.getElementById(sectionId);

    if (section) {
      const offsetTop =
        section.getBoundingClientRect().top + window.pageYOffset;
      const offset = 64; // Height of the AppBar, adjust this value as needed
      const scrollPosition = offsetTop - offset; // Subtract the AppBar height

      window.scrollTo({ top: scrollPosition, behavior: "smooth" });
    } else {
    }
  };
  const menuItemStyle: React.CSSProperties = {
    ...textStyle,
    fontWeight: 400,
    fontSize: "16px",
    textAlign: "center",
    textTransform: "capitalize",
  };

  const [bgColor, setBgColor] = React.useState("#FEFDEC");

  const [showNavBar, setShowNavBar] = React.useState(true);
  // Keep track of the last scroll position
  let lastScrollY = window.scrollY;

  const handleScroll = () => {
    if (window.scrollY > 0) {
      setBgColor("white"); // Set to white after scrolling past hero section
    } else {
      setBgColor("#FEFDEC"); // Set to transparent within hero section
    }
    const currentScrollY = window.scrollY;
    const heroSectionBottom = heroSectionRef.current
      ? heroSectionRef.current.getBoundingClientRect().bottom + window.scrollY
      : 700;

    if (currentScrollY < lastScrollY) {
      // After the hero section
      setShowNavBar(true);
    } else if (
      currentScrollY > lastScrollY &&
      currentScrollY > heroSectionBottom
    ) {
      // Within the hero section
      setShowNavBar(false);
    }

    lastScrollY = currentScrollY;

    const newOpacity = Math.max(0.9, 1 - window.scrollY / 300);
    setOpacity(newOpacity);
  };

  React.useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  type ArabicToSectionIdMapType = { [key: string]: string };

  const arabicToSectionIdMap: ArabicToSectionIdMapType = {
    الرئيسية: "home",
    "عن صك": "about sak",
    المميزات: "features",
    "كيف يعمل صك؟": "how it works?",
    "تواصل معنا": "contact us",
    "تحميل التطبيق": "download app",
    "download app": "download app",
  }; // Tabs for desktop and mobile views
  const tabs = [
    t("home"),
    t("aboutSakInner"),
    t("features"),
    t("howItWorks"),
    t("contactUs"),
  ];

  const handleCloseMobileMenu = () => {
    setIsMobileMenuOpen(false);
  };

  const handleOpenMobileMenu = () => {
    setIsMobileMenuOpen(true);
  };

  // Mobile Menu
  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Drawer
      anchor="top"
      keepMounted
      open={isMobileMenuOpen}
      onClose={handleCloseMobileMenu}
      sx={{
        zIndex: 1000,
      }}
      transitionDuration={500} // Set the duration for the slide effect
      PaperProps={{
        style: {
          direction: isRtl ? "rtl" : "ltr", // Add direction based on the language
          width: "100vw", // Full viewport width
          boxShadow: "none", // Remove any shadow
          padding: isRtl ? "100px 0 10px 20px" : "100px 20px 10px 10px", // Remove any padding
          borderRadius: "0px", // Make sure there are no rounded corners
          paddingTop: "80px", // Add padding to the top to account for the AppBar
          overflowX: "hidden", // Add this line to hide the horizontal scrollbar
        },
      }}
    >
      {tabs.map((tab) => (
        <Link
          activeClass="active"
          to={
            isRtl ? arabicToSectionIdMap[tab.toLowerCase()] : tab.toLowerCase()
          }
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}
          onSetActive={() => {
            setSelectedTab(tab);
            handleCloseMobileMenu();
          }}
          key={tab} // Ensure the 'key' prop is here for proper list rendering
          style={{
            display: "block",
            ...textStyle,
            fontWeight: tab === selectedTab ? 700 : 400,
            fontSize: tab === selectedTab ? "18px" : "16px",
            color: tab === selectedTab ? "#1C1B13" : "#807F7B",
            position: "relative", // Necessary for positioning the :after pseudo-element
            transition: "all 0.3s ease",
            marginRight: "20px",
            borderBottom: tab === selectedTab ? "2px solid #000000" : "none",
            cursor: "pointer",
          }}
        >
          <MenuItem sx={{ padding: isIpad || isTablet ? "15px 0" : "auto" }}>
            <Typography
              className={isRtl ? "rtl-text" : ""}
              variant="body1"
              style={menuItemStyle}
            >
              {tab}
            </Typography>
          </MenuItem>
        </Link>
      ))}
      {/* <LanguageButton
        startIcon={
          <Box ml={isRtl ? 1 : 0}>
            <NewLangIcon />
          </Box>
        }
        sx={{
          display: "flex", // Ensures flexbox layout
          flexDirection: "column", // Stacks items vertically
          alignItems: "center", // Centers items horizontally
          justifyContent: "center", // Centers items vertically
          width: "100%", // Full width
          minHeight: "45px",
          margin: "10px 0",
          marginRight: "10px", // Adjust margin based on RTL
          gap: "16px", // Space between text and icon
        }}
        onClick={() => {
          changeLanguage(i18n.language === "en" ? "ar" : "en");
          handleCloseMobileMenu();
        }}
      >
        {i18n.language === "en" ? "العربية" : "English"}
      </LanguageButton> */}
      <DownloadButton
        sx={{
          display: "flex", // Ensures flexbox layout
          flexDirection: "column", // Stacks items vertically
          alignItems: "center", // Centers items horizontally
          justifyContent: "center", // Centers items vertically
          width: "100%", // Full width
          minHeight: "45px",
          margin: isRtl ? "0 0 10px 0" : "10px 0", // Adjust margin based on RTL
          gap: "16px", // Space between text and icon
        }}
        startIcon={<NewDownloadIcon />}
        onClick={() => {
          handleNavigation("download app");
          handleCloseMobileMenu();
        }}
      >
        <div style={{ ...textStyle, marginRight: isRtl ? "10px" : "0" }}>
          {t("downloadApp")}
        </div>
      </DownloadButton>
    </Drawer>
  );

  return (
    <AppBar
      position="fixed"
      color="default"
      elevation={0}
      style={{
        backgroundColor: isMobileMenuOpen ? "#FFF" : bgColor,
        direction: i18n.language === "ar" ? "rtl" : "ltr",
        transition: "opacity 0.5s ease, transform 0.5s ease",
        opacity: isTransitioning ? 0 : 1,
        transform: `translateY(${showNavBar ? "0" : "-100%"})`, // Hide or show based on showNavBar
        zIndex: 1200,
      }}
    >
      <Container maxWidth="lg">
        <Toolbar
          disableGutters
          sx={{
            display: "flex",
            justifyContent: "space-between",
            padding: " 0px 10px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent:
                isMobile || isIpad || isTablet ? "space-between" : "flex-start", // This creates space between the child elements
              alignItems: "center", // This vertically centers the child elements
              width: isMobile || isIpad || isTablet ? "100%" : "auto", // Ensure the box takes full width
            }}
          >
            <img
              src={sakLogo3X}
              alt="Logo"
              style={{
                height: "50px",
                borderRadius: "5px",
                margin:
                  isMobile || isIpad || isTablet
                    ? "0"
                    : isRtl
                    ? "0 0 0 20px"
                    : "0 20px 0 0",
                cursor: "pointer",
              }}
              onClick={scrollToTop}
            />

            {isMobile || isIpad || isTablet ? (
              <IconButton
                size="large"
                aria-label="show more"
                aria-controls={mobileMenuId}
                aria-haspopup="true"
                color="inherit"
              >
                {isMobileMenuOpen ? (
                  <NewXIcon
                    style={{
                      height: "50px",
                      width: "50px",
                      padding: "5px",
                    }}
                    onClick={handleCloseMobileMenu}
                  />
                ) : (
                  <NewHamburgerIcon
                    style={{
                      height: "50px",
                      width: "50px",
                      padding: "5px",
                    }}
                    onClick={handleOpenMobileMenu}
                  />
                )}
              </IconButton>
            ) : null}
          </Box>

          {/* Tabs for desktop view */}
          <Box
            sx={{
              flexGrow: 1,
              display: isMobile || isIpad || isTablet ? "none" : "flex",
              ml: 5,
            }}
          >
            {tabs.map((tab, index) => (
              <Link
                key={index}
                activeClass="active"
                to={
                  isRtl
                    ? arabicToSectionIdMap[tab.toLowerCase()]
                    : tab.toLowerCase()
                } // The 'to' prop should match the section ID
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
                onSetActive={() => setSelectedTab(tab)}
                style={{
                  display: "block",
                  ...textStyle,
                  fontWeight: tab === selectedTab ? 700 : 400,
                  fontSize: tab === selectedTab ? "18px" : "16px",
                  color: tab === selectedTab ? "#1C1B13" : "#807F7B",
                  position: "relative",
                  transition: "all 0.3s ease",
                  marginRight: "20px",
                  borderBottom:
                    tab === selectedTab ? "2px solid #000000" : "none",
                  cursor: "pointer",
                }}
              >
                <Typography
                  className={isRtl ? "rtl-text" : ""}
                  paddingBottom={0.5}
                  marginRight={1}
                  marginLeft={1}
                  variant="body1"
                  style={menuItemStyle}
                >
                  {tab}
                </Typography>
              </Link>
            ))}
          </Box>

          {/* Render mobile menu */}
          {renderMobileMenu}

          {/* Other Toolbar Elements */}
          <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
            {isMobile || isIpad || isTablet ? null : (
              <>
                {/* <LanguageButton
                  startIcon={<NewLangIcon />}
                  sx={{
                    gap: "16px",
                    alignItems: "center",
                    justifyContent: "center",
                    minWidth: "120px",
                    minHeight: "45px",
                    margin:
                      isMobile || isIpad || isTablet
                        ? "0"
                        : isRtl
                        ? "0 0 0 20px"
                        : "0 20px 0 0",
                  }}
                  onClick={() =>
                    changeLanguage(i18n.language === "en" ? "ar" : "en")
                  }
                >
                  <div
                    style={{ ...textStyle, marginRight: isRtl ? "10px" : "0" }}
                  >
                    {i18n.language === "en" ? "العربية" : "English"}
                  </div>
                </LanguageButton> */}
                <DownloadButton
                  sx={{
                    gap: "16px",
                    alignItems: "center",
                    justifyContent:
                      isMobile || isIpad || isTablet
                        ? "center"
                        : isRtl
                        ? "flex-start"
                        : "flex-start",
                  }}
                  startIcon={<NewDownloadIcon />} // Icon on the left of the text for Arabic
                  onClick={() => handleNavigation("download app")}
                >
                  <div
                    style={{ ...textStyle, marginRight: isRtl ? "10px" : "0" }}
                  >
                    {t("downloadApp")}
                  </div>
                </DownloadButton>
              </>
            )}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default NavBar;
