import React from "react";
import { NavLink } from "react-router-dom";
import Logo from '../../images/logo/sakk-logo.svg';
import IconDownload from '../../images/icons/icon-download.png';

const NavbarLanding = () => {
  return (
    <nav className="navbar-landing">
      <div className="container">
        <div className="w-100 d-flex align-items-center justify-content-between">
          <NavLink
            className="navbar-landing__logo"
            to="https://www.getsak.com/"
            target="_self"
          >
            <img src={Logo} alt="sakk logo" className="logo" />
          </NavLink>
          <NavLink
            className="navbar-landing__download"
            to="https://app.adjust.com/1ey95ybc?engagement_type=fallback_click"
            target="_blank"
          >
            <img src={IconDownload} alt="sakk logo" className="download" />
            <span className="text">تحميل التطبيق</span>
          </NavLink>
        </div>
      </div>
    </nav>
  );
};

export default NavbarLanding;
