import React from "react";
import AboutFullComponent from "./AboutFullComponent";
import {
  Box,
  Container,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import InnerNavBar from "../../components/InnerNavbar/InnerNavbar";
import Footer from "../../components/Footer/Footer";
import { useTranslation } from "react-i18next";
import CustomBreadcrumb from "../../components/Breadcrumb/Breadcrumb";

import { textStyle } from "../../shared/styles";
import CustomHelmet from "../../components/Helmet/Helmet";

const AboutFullPage: React.FC = () => {
  const { t, i18n } = useTranslation();
  const isRtl = i18n.language === "ar";
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [backgroundStyle, setBackgroundStyle] = React.useState({
    background: "linear-gradient(to right, #FEFDFB00, #FEFDF9)",
  });

  React.useEffect(() => {
    const handleScroll = () => {
      const topGradient = "linear-gradient(to right, #FEFDFB00, #FEFDF9)";
      const scrollGradient =
        "linear-gradient(to right, #F2F2F9, #F4F4F8, #F7F7F6, #FAF9F1, #FBF9ED, #FCFBE8, #FDFBEA)";

      // Check if the page is at the top
      if (window.scrollY === 0) {
        setBackgroundStyle({ background: topGradient });
      } else {
        setBackgroundStyle({ background: scrollGradient });
      }
    };

    // Add scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <CustomHelmet title={t("sak") + " - " + t("about")} />
      <InnerNavBar />
      <CustomBreadcrumb
        pageName={t("about")}
        backgroundStyle={backgroundStyle}
        isMobile={isMobile}
        isRtl={isRtl}
        mainTitle={t("AboutSakMainText")}
      />
      <AboutFullComponent />;
      <Footer />
    </>
  );
};

export default AboutFullPage;
