import React from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  IconButton,
  Box,
  Grid,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { textStyle } from "../../shared/styles";

import multiPayment3X from "../../assets/multiPaymentpopup3X.png";

import { useTranslation } from "react-i18next";

interface PopupProps {
  open: boolean;
  onClose: () => void;
  isIpad: boolean;
}

const MultiPaymentPopup: React.FC<PopupProps> = ({ open, onClose, isIpad }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { t, i18n } = useTranslation();
  const isRtl = i18n.language === "ar";

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          padding: "24px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "24px",
          boxShadow: "0px 4px 10px rgba(200, 200, 200, 0.3)",
          borderRadius: "24px",
          minWidth: isIpad ? "80%" : "55%",
          width: isMobile || isIpad ? "90%" : "500px",
          height: isMobile || isIpad ? "60%" : "auto",
          maxHeight: isMobile ? "90%" : "auto",
          direction: isRtl ? "right" : "left",
        },
      }}
      fullScreen={isMobile || isIpad}
    >
      <DialogTitle
        sx={{
          ...textStyle,
          fontSize: isMobile ? "24px" : "32px", // Smaller font size on mobile
          lineHeight: "130%",
          color: "#1C1B13",
          margin: isIpad ? "20px 0 " : "auto",
          textAlign: "center",
        }}
      >
        {t("multiMethodTitle")}{" "}
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Typography
          className={isRtl ? "rtl-text" : ""}
          gutterBottom
          sx={{
            ...textStyle,
            color: "#1C1B13",
            textAlign: "center",
            fontSize: "18px", // Smaller font size on mobile
            margin: isIpad ? "20px 0" : "auto",
          }}
        >
          {t("multiMethodDesc")}{" "}
        </Typography>
        <Grid
          container
          spacing={2}
          justifyContent="center"
          sx={{
            display: "flex",
            flexDirection: "row",
            backgroundColor: "#F7F7FF",
            padding: "20px",
            marginTop: "40px",
            borderRadius: "20px",
            minHeight: "350px",
          }}
        >
          <Box>
            <img
              src={multiPayment3X}
              alt="Multi Payment"
              style={{ width: "100%" }}
            />
          </Box>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default MultiPaymentPopup;
