import {
  useMediaQuery,
  useTheme,
  Container,
  Paper,
  Box,
  Typography,
} from "@mui/material";

import { useTranslation } from "react-i18next";
import { useLanguageTransition } from "../../LanguageTransitionContext";
import ContactForm from "../Home/ContactUS/ContactForm";
import axios from "axios";
import React from "react";

import { useNavigate } from "react-router-dom";
import { baseURL } from "../../shared/constants";
import { textStyle, textStyleRegular } from "../../shared/styles";

import { ReactComponent as NewHowItContact1Icon } from "../../assets/call.svg";
import { ReactComponent as NewHowItContact2Icon } from "../../assets/sms.svg";
import { ReactComponent as NewHowItContact3Icon } from "../../assets/location.svg";
import ContactDetails from "../Home/ContactUS/ContactDetails";

const ContactUsFullComponent = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isIpad = useMediaQuery("(min-width:768px) and (max-width:1200px)");
  const isTablet = useMediaQuery("(max-width: 1024px)"); // iPad size

  const { isTransitioning } = useLanguageTransition();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const isRtl = i18n.language === "ar";

  React.useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  const handleSubmit = async (data: any) => {
    const headers = {
      "X-Api-Version": "v2",
      "Accept-Language": i18n.language, // Using the current language from i18n
      "Content-Type": "application/json",
    };

    const dataToSend = {
      name: data.name,
      phone_number: data.mobileNumber,
      user_type: data.userType,
      inquiry_type: data.queryType,
      message: data.message,
    };
    try {
      const response = await axios.post(
        `${baseURL}/contact-us/landing-page`,
        dataToSend,
        { headers }
      );
      navigate("/contact-us-submitted", { state: { data: response.data } });
    } catch (error) {
      navigate("/something-went-wrong", { state: { data: "error" } });
    }
  };
  return (
    <div
      style={{
        transition: "opacity 0.5s ease, transform 0.5s ease",
        opacity: isTransitioning ? 0 : 1,
        transform: isTransitioning
          ? `translateX(${isTransitioning ? "-30px" : "30px"})`
          : "translateX(0)",
        marginTop: "250px",
      }}
    >
      <Container maxWidth="lg">
        <Paper elevation={0}>
          <Box
            sx={{
              display: "flex",
              flexDirection: isMobile || isIpad || isTablet ? "column" : "row", // Adjust direction for RTL
              alignItems: "flex-start",
              gap: isMobile ? "32px" : "60px",
            }}
          >
            {(isMobile || isIpad || isTablet) && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: isMobile ? "95%" : "100%",
                  height: "244px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    height: "53px",
                    gap: 6,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "flex-start",
                      gap: 2,
                      flexDirection: isRtl ? "row-reverse" : "row",
                    }}
                  >
                    <NewHowItContact1Icon />
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 1,
                        textAlign: isRtl ? "right" : "left", // Align text based on language
                      }}
                    >
                      <Typography
                        className={isRtl ? "rtl-text" : ""}
                        variant="body1"
                        sx={{
                          ...textStyleRegular,
                          fontSize: "16px",
                          color: "#1C1B13",
                          fontWeight: 400,
                          lineHeight: "150%",
                        }}
                      >
                        {t("callUs")}
                      </Typography>
                      <Typography
                        className={isRtl ? "rtl-text" : ""}
                        variant="body2"
                        sx={{
                          ...textStyle,
                          fontSize: "16px",
                          color: "#1C1B13",
                          fontWeight: 500,
                          lineHeight: "130%",
                        }}
                      >
                        920033232
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "flex-start",
                      gap: 2,
                      flexDirection: isRtl ? "row-reverse" : "row",
                    }}
                  >
                    <NewHowItContact2Icon />
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 1,
                        textAlign: isRtl ? "right" : "left", // Align text based on language
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          width: "100%",
                          height: "53px",
                        }}
                      >
                        {/* Email */}
                        <Typography
                          className={isRtl ? "rtl-text" : ""}
                          variant="body1"
                          sx={{
                            ...textStyleRegular,
                            fontSize: "16px",
                            color: "#1C1B13",
                            fontWeight: 400,
                            lineHeight: "150%",
                          }}
                        >
                          {t("emailAddress")}
                        </Typography>
                        <Typography
                          className={isRtl ? "rtl-text" : ""}
                          variant="body2"
                          sx={{
                            ...textStyle,
                            fontSize: "16px",
                            color: "#1C1B13",
                            fontWeight: 500,
                            lineHeight: "130%",
                          }}
                        >
                          support@getsak.com
                        </Typography>
                      </Box>
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "flex-start",
                      gap: 2,
                      flexDirection: isRtl ? "row-reverse" : "row",
                    }}
                  >
                    <NewHowItContact3Icon />
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                        height: "74px",
                        textAlign: isRtl ? "right" : "left", // Align text based on language
                      }}
                    >
                      {/* Location */}
                      <Typography
                        className={isRtl ? "rtl-text" : ""}
                        variant="body1"
                        sx={{
                          ...textStyleRegular,
                          fontSize: "16px",
                          color: "#1C1B13",
                          fontWeight: 400,
                          lineHeight: "150%",
                        }}
                      >
                        {t("location")}
                      </Typography>
                      <Typography
                        className={isRtl ? "rtl-text" : ""}
                        variant="body2"
                        sx={{
                          ...textStyle,
                          fontSize: "16px",
                          color: "#1C1B13",
                          fontWeight: 500,
                          lineHeight: "130%",
                        }}
                      >
                        {t("locationDetails")}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            )}
            {!(isMobile || isIpad || isTablet) && (
              <ContactDetails isMobile={isMobile} />
            )}
            <ContactForm isMobile={isMobile} handleSubmit={handleSubmit} />{" "}
          </Box>
        </Paper>
      </Container>
    </div>
  );
};

export default ContactUsFullComponent;
