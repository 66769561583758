import { Box, useTheme, useMediaQuery, Container } from "@mui/material";

import How3XEN from "../../../assets/How3XEN.png";
import How3XAR from "../../../assets/HowITWORKS3XF.png";

import { ContentSection } from "./ContentSection";
import { useTranslation } from "react-i18next";
import { useLanguageTransition } from "../../../LanguageTransitionContext";

const HowItWorksComponent = ({ id }: any) => {
  const { i18n } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isRtl = i18n.language === "ar"; // Check if language is Arabic
  const { isTransitioning } = useLanguageTransition();
  const isIpad = useMediaQuery("(min-width:768px) and (max-width:1200px)");

  return (
    <div
      id={id}
      style={{
        transition: "opacity 0.5s ease, transform 0.5s ease",
        opacity: isTransitioning ? 0 : 1,
        transform: isTransitioning
          ? `translateX(${isTransitioning ? "-30px" : "30px"})`
          : "translateX(0)",
      }}
    >
      <Container maxWidth="lg">
        <Box
          sx={{
            backgroundColor: "transparent",
            padding: "20px 10px",
            width: "100%",
            display: "flex",
            flexDirection: isMobile ? "column" : "row",
            alignItems: "center",
            gap: 2,
            direction: isRtl ? "rtl" : "ltr", // Adjust direction based on language
          }}
        >
          <ContentSection />
          <img
            src={isRtl ? How3XAR : How3XEN}
            alt="How it works"
            style={{
              width: isIpad ? "60%" : "100%",
              maxWidth: isMobile ? "300px" : "500px",
              borderRadius: "16px",
              order: isRtl ? 2 : 1, // Change image order for RTL
            }}
          />
        </Box>
      </Container>
    </div>
  );
};

export default HowItWorksComponent;
