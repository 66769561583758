import { textStyle } from "../../shared/styles";
import Button from "@mui/material/Button";

import { styled } from "@mui/material/styles";
export const StyledButton = styled(Button)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  padding: "12px 16px",
  gap: "8px",
  width: "100%",
  height: "45px",
  background: "#EBEBFF",
  borderRadius: "14px",
  color: "#6263AB",
  ...textStyle,
  fontWeight: 700,
  fontSize: "14px",
  lineHeight: "150%",
  textAlign: "center",
  marginTop: "20px",
  textTransform: "none", // Add this if you don't want MUI to automatically capitalize the button text
  // Include media queries or other conditional styles as needed
  "&:hover": {
    backgroundColor: "#dcdcff", // Adjust the hover background color as needed
  },
  // Assuming you want the button to be hidden initially as per the commented 'display: none'
  // display: 'none',
});
