// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../src/fonts/Linotype - DIN Next LT Arabic Medium.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../src/fonts/Linotype - DIN Next LT Arabic Regular.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `


/* @font-face {
  font-family: "DIN Next LT Arabic Medium";
  src: url("https://db.onlinewebfonts.com/t/aba1a083bf50980a05f0265179103a09.eot");
  src: url("https://db.onlinewebfonts.com/t/aba1a083bf50980a05f0265179103a09.eot?#iefix")format("embedded-opentype"),
  url("https://db.onlinewebfonts.com/t/aba1a083bf50980a05f0265179103a09.woff2")format("woff2"),
  url("https://db.onlinewebfonts.com/t/aba1a083bf50980a05f0265179103a09.woff")format("woff"),
  url("https://db.onlinewebfonts.com/t/aba1a083bf50980a05f0265179103a09.ttf")format("truetype"),
  url("https://db.onlinewebfonts.com/t/aba1a083bf50980a05f0265179103a09.svg#DIN Next LT Arabic Medium")format("svg");
} */


@font-face {
  font-family: 'DIN Next LT Arabic Medium';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('truetype');
  font-weight: normal;
  font-style: normal;
}


@font-face {
  font-family: 'DIN Next LT Arabic Regular';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format('truetype');
  font-weight: normal;
  font-style: normal;
}

.rtl-text {
  direction: rtl;
  unicode-bidi: embed;
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":";;;AAGA;;;;;;;;GAQG;;;AAGH;EACE,wCAAwC;EACxC,+DAAoF;EACpF,mBAAmB;EACnB,kBAAkB;AACpB;;;AAGA;EACE,yCAAyC;EACzC,+DAAqF;EACrF,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,cAAc;EACd,mBAAmB;AACrB","sourcesContent":["\n\n\n/* @font-face {\n  font-family: \"DIN Next LT Arabic Medium\";\n  src: url(\"https://db.onlinewebfonts.com/t/aba1a083bf50980a05f0265179103a09.eot\");\n  src: url(\"https://db.onlinewebfonts.com/t/aba1a083bf50980a05f0265179103a09.eot?#iefix\")format(\"embedded-opentype\"),\n  url(\"https://db.onlinewebfonts.com/t/aba1a083bf50980a05f0265179103a09.woff2\")format(\"woff2\"),\n  url(\"https://db.onlinewebfonts.com/t/aba1a083bf50980a05f0265179103a09.woff\")format(\"woff\"),\n  url(\"https://db.onlinewebfonts.com/t/aba1a083bf50980a05f0265179103a09.ttf\")format(\"truetype\"),\n  url(\"https://db.onlinewebfonts.com/t/aba1a083bf50980a05f0265179103a09.svg#DIN Next LT Arabic Medium\")format(\"svg\");\n} */\n\n\n@font-face {\n  font-family: 'DIN Next LT Arabic Medium';\n  src: url('../src/fonts/Linotype - DIN Next LT Arabic Medium.ttf') format('truetype');\n  font-weight: normal;\n  font-style: normal;\n}\n\n\n@font-face {\n  font-family: 'DIN Next LT Arabic Regular';\n  src: url('../src/fonts/Linotype - DIN Next LT Arabic Regular.ttf') format('truetype');\n  font-weight: normal;\n  font-style: normal;\n}\n\n.rtl-text {\n  direction: rtl;\n  unicode-bidi: embed;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
