import React from "react";
import NotFoundComponent from "./NotFoundComponent";
import { Container } from "@mui/material";
import InnerNavBar from "../../components/InnerNavbar/InnerNavbar";

const NotFoundPage: React.FC = () => {
  return (
    <>
      <Container maxWidth="lg">
        <InnerNavBar isTransparent={true} />
      </Container>
      <NotFoundComponent />
    </>
  );
};

export default NotFoundPage;
