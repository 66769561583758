import React from "react";
import { useMediaQuery, Container, Paper, Box, Grid } from "@mui/material";
import axios from "axios";

import StyledBox from "../Home/FAQ/StyledBox";
import FAQItem from "../Home/FAQ/FaqItem";
import { useTranslation } from "react-i18next";
import { useLanguageTransition } from "../../LanguageTransitionContext";
import { baseURL } from "../../shared/constants";

const FaqsFullComponent = () => {
  const isIpad = useMediaQuery("(min-width:768px) and (max-width:1200px)");

  const { t, i18n } = useTranslation();
  const isRtl = i18n.language === "ar"; // Check if language is Arabic
  const { isTransitioning } = useLanguageTransition();
  const [faqs, setFaqs] = React.useState<any>([]);

  const getFaqs = async () => {
    const headers = {
      "X-Api-Version": "v2",
      "Accept-Language": i18n.language, // Assuming i18n is defined earlier
      "Content-Type": "application/json",
    };

    try {
      const response = await axios.get(`${baseURL}/contact-us/faq`, {
        headers,
      });
      setFaqs(response.data.data.data);
    } catch (error) {
      console.log({ error });
    }
  };

  React.useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    getFaqs();
  }, [i18n.language]); // Add i18n.language as a dependency to refetch FAQs on language change

  return (
    <div
      style={{
        transition: "opacity 0.5s ease, transform 0.5s ease",
        opacity: isTransitioning ? 0 : 1,
        transform: isTransitioning ? `translateX(-30px)` : "translateX(0)",
        marginTop: "200px",
        marginBottom: "60px",
      }}
    >
      <Paper elevation={0} sx={{ py: 4, overflow: "hidden" }}>
        <Container maxWidth="lg">
          <Grid
            container
            spacing={{ xs: 2, sm: 3, md: 4 }}
            direction={isRtl ? "row-reverse" : "row"}
          >
            <Grid item xs={12} sm={isIpad ? 12 : 8} md={isIpad ? 12 : 8}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                {faqs?.length === 0 && (
                  <>
                    <FAQItem
                      title={t("faqItem1Title")}
                      content={t("faqItem1Content")}
                    />
                    <FAQItem
                      title={t("faqItem2Title")}
                      content={t("faqItem2Content")}
                    />
                    <FAQItem
                      title={t("faqItem3Title")}
                      content={t("faqItem3Content")}
                    />
                    <FAQItem
                      title={t("faqItem4Title")}
                      content={t("faqItem4Content")}
                    />
                    <FAQItem
                      title={t("faqItem5Title")}
                      content={t("faqItem5Content")}
                    />
                    <FAQItem
                      title={t("faqItem6Title")}
                      content={t("faqItem6Content")}
                    />
                  </>
                )}
                {faqs?.map((faq: any) => (
                  <FAQItem
                    key={faq.id}
                    title={
                      i18n.language === "ar"
                        ? faq.attributes.Question_ar
                        : faq.attributes.Question_en
                    }
                    content={
                      i18n.language === "ar"
                        ? faq.attributes.Answer_ar
                        : faq.attributes.Answer_en
                    }
                  />
                ))}
              </Box>
            </Grid>
            <Grid item xs={12} sm={isIpad ? 12 : 8} md={isIpad ? 12 : 4}>
              <StyledBox isIpad={isIpad} />
            </Grid>
          </Grid>
        </Container>
      </Paper>
    </div>
  );
};

export default FaqsFullComponent;
