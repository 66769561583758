import { Box, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { textStyleRegular } from "../../../shared/styles";
import { useTranslation } from "react-i18next";
import React from "react";
import { ReactComponent as DropDownArrowIcon } from "../../../assets/chevern-down.svg";

const DropdownMenu = ({ queryType, setQueryType }: any) => {
  const { t, i18n } = useTranslation();
  const isRtl = i18n.language === "ar"; // Check if language is Arabic
  const [open, setOpen] = React.useState(false);

  const handleChange = (event: any) => {
    setQueryType(event.target.value);
  };

  const handleClose = () => {
    setOpen(false);
  };

  React.useEffect(() => {
    const closeDropdownOnScroll = () => {
      handleClose();
    };

    window.addEventListener("scroll", closeDropdownOnScroll);

    return () => {
      window.removeEventListener("scroll", closeDropdownOnScroll);
    };
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "4px",
        width: "100%",
        direction: isRtl ? "rtl" : "ltr", // Adjust direction based on the language
      }}
    >
      <InputLabel sx={{ ...textStyleRegular, mb: 1, color: "#1C1B13" }}>
        {t("queryType")}
      </InputLabel>
      <FormControl fullWidth variant="outlined">
        <Select
          value={queryType}
          open={open}
          onOpen={() => setOpen(true)}
          onClose={handleClose}
          onChange={handleChange}
          displayEmpty
          IconComponent={DropDownArrowIcon}
          sx={{
            ...textStyleRegular,
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "14px",
            lineHeight: "150%",
            color: "#1C1B13",
            height: "48px",
            backgroundColor: "#FFFFFF",
            border: "1px solid #F0F0EF",
            boxShadow: "0px 4px 12px rgba(196, 196, 196, 0.1)",
            borderRadius: "8px",
            "& .MuiSelect-select": {
              display: "flex",
              alignItems: "center",
              paddingRight: "0px", // Override right padding
              textAlign: isRtl ? "right" : "left",
            },

            "&& .MuiSelect-select": {
              paddingRight: "9px",

              // color: "#A09F9C",
            },
            "& .MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
            "& .MuiSelect-icon": {
              right: isRtl ? "inherit" : "12px",
              left: isRtl ? "12px" : "inherit",
            },
          }}
        >
          <MenuItem
            value=""
            sx={{
              ...textStyleRegular,
              direction: isRtl ? "rtl" : "ltr", // Adjust direction based on the language
            }}
            disabled
          >
            {t("selectQueryType")}
          </MenuItem>
          <MenuItem
            value="option1"
            sx={{
              ...textStyleRegular,
              direction: isRtl ? "rtl" : "ltr", // Adjust direction based on the language
            }}
          >
            {t("option1")}
          </MenuItem>
          <MenuItem
            value="option2"
            sx={{
              ...textStyleRegular,
              direction: isRtl ? "rtl" : "ltr", // Adjust direction based on the language
            }}
          >
            {t("option2")}
          </MenuItem>
          <MenuItem
            value="option3"
            sx={{
              ...textStyleRegular,
              direction: isRtl ? "rtl" : "ltr", // Adjust direction based on the language
            }}
          >
            {t("option3")}
          </MenuItem>
          <MenuItem
            value="option4"
            sx={{
              ...textStyleRegular,
              direction: isRtl ? "rtl" : "ltr", // Adjust direction based on the language
            }}
          >
            {t("option4")}
          </MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
};

export default DropdownMenu;
