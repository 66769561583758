import React from "react";
import { Typography, Box, Container, useMediaQuery, Grid } from "@mui/material";
import ServicesSection from "./ServicesCards";
import { textStyle } from "../../../shared/styles";
import { useTranslation } from "react-i18next";
import { useLanguageTransition } from "../../../LanguageTransitionContext";

const FeaturesComponent = ({ id }: any) => {
  const { t, i18n } = useTranslation();
  const isRtl = i18n.language === "ar";
  const { isTransitioning } = useLanguageTransition();
  const isIpad = useMediaQuery("(min-width:768px) and (max-width:1200px)");

  return (
    <div
      id={id}
      style={{
        transition: "opacity 0.5s ease, transform 0.5s ease",
        opacity: isTransitioning ? 0 : 1,
        transform: isTransitioning ? `translateX(-30px)` : "translateX(0)",
      }}
    >
      <Box
        sx={{
          backgroundColor: "#F8F8FB",
          py: 2,
          px: 1,
          width: "100%",
        }}
      >
        <Container
          maxWidth="lg"
          sx={{
            display: "flex",
            flexDirection: {
              xs: isIpad ? "row" : "column",
              md: isIpad ? "column" : "row",
            },
            alignItems: "center",
            justifyContent: "center",
            direction: isRtl ? "rtl" : "ltr",
          }}
        >
          <Grid
            container
            spacing={2}
            alignItems="center"
            justifyContent="center"
          >
            <Grid item xs={12} md={isIpad ? 12 : 5}>
              <Box
                sx={{
                  textAlign: {
                    xs: "center",
                    md: isIpad ? "center" : isRtl ? "right" : "left",
                  },
                  pb: 2,
                  animation: "fadeIn 1s ease-in-out",
                  marginLeft: { xs: "0", md: "0" },
                }}
              >
                <Typography
                  className={isRtl ? "rtl-text" : ""}
                  variant="subtitle1"
                  sx={{
                    ...textStyle,
                    fontWeight: "700",
                    fontSize: "18px",
                    lineHeight: "150%",
                    color: "#6263AB",
                    mt: 2,
                  }}
                >
                  {t("appFeaturesTitle")}
                </Typography>
                <Typography
                  className={isRtl ? "rtl-text" : ""}
                  variant="h3"
                  sx={{
                    ...textStyle,
                    fontWeight: "bold",
                    fontSize: { xs: "26px", md: "32px" },
                    lineHeight: "130%",
                    color: "#1C1B13",
                    minWidth: "350px",
                  }}
                >
                  {t("whyChooseSak")}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={isIpad ? 12 : 7}>
              <ServicesSection />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </div>
  );
};

export default FeaturesComponent;
