/* eslint-disable react-hooks/exhaustive-deps */
// MainPage.tsx

import React, { useEffect, useState } from "react";
import LandingPageForm from "../../components/landing-page/landing-page-form";
import LandingPageContent from "../../components/landing-page/landing-page-content";
import { useParams } from "react-router-dom";
import { GetData } from "../../components/api/index";
import Loading from "../../components/loading/index";
import CustomHelmet from "../../components/Helmet/Helmet";
import NavbarLanding from "../../components/navbar-landing";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";
import 'react-phone-input-2/lib/style.css';

const LandingPage: React.FC = () => {
  const { id } = useParams();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    GetData(id, setData, setLoading);
  }, [id]);

  if (loading === false) {
    return (
      <>
        <Loading />
      </>
    );
  }

  return (
    <>
      <CustomHelmet title={(data as { Name_ar?: string })?.Name_ar} />
      <main>
        <section
          className="main-page"
          style={{
            backgroundImage: `url(${
              (data as { landing_page?: { CoverImage?: { url?: string } } })
                .landing_page?.CoverImage?.url
            })`,
          }}
        >
          <div className="overlay">
            <NavbarLanding />
            <div className="landing-page">
              <div className="container h-100">
                <div className="landing-page__flex-container">
                  <LandingPageContent  Landingpage={data}/>
                  <LandingPageForm className="landing-page__form-web" />
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <section className="landing-page-mob">
        <div className="container">
          <LandingPageForm className="landing-page__form-mob" />
        </div>
      </section>
    </>
  );
};

export default LandingPage;
