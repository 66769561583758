
const Loading = () => {
  return (
    <div className="loading">
      <div className="loading__spinner"></div>
    </div>
  )
}
export default Loading;

