import React from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  IconButton,
  Box,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { textStyle } from "../../shared/styles";
import exploreIcon3X from "../../assets/explore3X.png";
import { useTranslation } from "react-i18next";

const DiscoverPopUp = ({ open, onClose, isIpad }: any) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { t, i18n } = useTranslation();
  const isRtl = i18n.language === "ar"; // Check if language is Arabic

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullScreen={isMobile || isIpad}
      PaperProps={{
        sx: {
          padding: "24px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "24px",
          boxShadow: "0px 4px 10px rgba(200, 200, 200, 0.3)",
          borderRadius: "24px",
          minWidth: isIpad ? "80%" : "55%",
          width: isMobile || isIpad ? "90%" : "500px",
          height: isMobile || isIpad ? "60%" : "auto",
          maxHeight: isMobile ? "90%" : "auto",
        },
      }}
    >
      <DialogTitle
        sx={{
          ...textStyle,
          fontSize: isMobile ? "24px" : "32px", // Smaller font size on mobile
          color: "#1C1B13",
          textAlign: "center",
          margin: isIpad ? "20px 0 " : "auto",
        }}
      >
        <Typography
          className={isRtl ? "rtl-text" : ""}
          sx={{
            ...textStyle,
            color: "#1C1B13",
            textAlign: "center",
            fontWeight: 700,
            fontSize: isMobile ? "24px" : "32px", // Smaller font size on mobile
            flexGrow: 1, // Allow the text to take available space
          }}
        >
          {t("exploreTitle")}
        </Typography>

        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Typography
          className={isRtl ? "rtl-text" : ""}
          gutterBottom
          sx={{
            ...textStyle,
            color: "#1C1B13",
            textAlign: "center",
            fontSize: "18px", // Smaller font size on mobile
            margin: isIpad ? "20px 0" : "auto",
          }}
        >
          {t("exploreDesc")}
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            overflow: "hidden",
            maxWidth: "100%",
            padding: "20px",
            marginTop: "40px",
          }}
        >
          <img
            src={exploreIcon3X}
            alt="Explore"
            style={{ maxWidth: "100%", height: "auto" }}
          />
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default DiscoverPopUp;
