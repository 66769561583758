import React from "react";
type Props = {
  Label?: string;
  Value?: string;
  SetValue?: any;
  Placeholder?: string;
  ErrorInput?: any;
  setErrorsInput?: any;
};
export function InputPhonecountry(props: Props) {
  const { Label, Value, SetValue, Placeholder, ErrorInput, setErrorsInput } =
    props;
  return (
    <div className="form-group">
      <label>
        <span className="size_footnote_f1">{Label}</span>
      </label>
      <div className="tel-phone">
        <span className="tel-phone-code">966</span>
        <div className="line"></div>
        <input
          type="tel"
          id="phone"
          name="phone"
          placeholder={Placeholder}
          //minLength={9}
          //maxLength={9}
          value={Value}
          onInput={(e) => {
            e.currentTarget.value = e.currentTarget.value.replace(
              /^(\d{2})-?(\d{3})-?(\d{4})$/g,
              "$1 $2 $3"
            );
          }}
          required
          onChange={(e) => {
            // Max Length 9
            if (e.target.value.length > 11) {
              return false;
            } else {
              if (e.target.value.slice(0, 1) === "5") {
                setErrorsInput({ ...ErrorInput, phone: "" });
                SetValue(e.target.value);
              } else if (e.target.value.length === 0) {
                setErrorsInput({ ...ErrorInput, phone: "" });
              } else {
                setErrorsInput({
                  ...ErrorInput,
                  phone: "رقم الجوال يجب ان يبدا بـ 5",
                });
                return;
              }
            }
          }}
        />
      </div>
      {ErrorInput.phone && (
        <span className="errorfiled">{ErrorInput.phone}</span>
      )}
    </div>
  );
}
/*

      <PhoneInput
        country={"sa"}
        countryCodeEditable={false}
        value={Value}
        onChange={(phone) => {
          
          if (phone.slice(0, 4) === "9665") {
            setErrorsInput({ ...ErrorInput, phone: "" });
            SetValue(phone);
          } else {
            setErrorsInput({
              ...ErrorInput,
              phone: "رقم الجوال يجب ان يبدا بـ 5",
            });
            return;
          }
        }}
        containerClass={
          ErrorInput.phone
            ? "form-control-phone is-invalid"
            : "form-control-phone"
        }
        inputProps={{
          required: true,
          autoFocus: false,
          autoComplete: "off",
          className: "form-input",
          maxLength: 16,
          placeHolder : "05X - XXX - XX"
        }}
        placeholder={"05X - XXX - XX"}
      />
      
       
*/
