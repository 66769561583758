import {
  Box,
  Typography,
  Grid,
  useTheme,
  useMediaQuery,
  Container,
} from "@mui/material";

import download3XEN from "../../../assets/DownloadApp3XEN.png";
import download3XAR from "../../../assets/DownloadApp3XAR.png";
import googlePlayBadge3X from "../../../assets/GooglePlayBadge3X.png";
import appleStoreBadge3X from "../../../assets/AppleStoreBadge3X.png";
import { textStyle, textStyleRegular } from "../../../shared/styles";
import { useTranslation } from "react-i18next";
import { useLanguageTransition } from "../../../LanguageTransitionContext";

const DownloadAppComponent = ({ id, showArrow = true }: any) => {
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isIpad = useMediaQuery("(min-width:768px) and (max-width:1200px)");
  const isTablet = useMediaQuery("(max-width: 1024px)"); // iPad size

  const isRtl = i18n.language === "ar"; // Check if language is Arabic
  const { isTransitioning } = useLanguageTransition();

  return (
    <div
      id={id}
      style={{
        transition: "opacity 0.5s ease, transform 0.5s ease",
        opacity: isTransitioning ? 0 : 1,
        transform: isTransitioning
          ? `translateX(${isTransitioning ? "-30px" : "30px"})`
          : "translateX(0)",
      }}
    >
      <Container maxWidth="lg">
        <Box
          sx={{
            backgroundColor: "transparent",
            py: 5,
            direction: isRtl ? "rtl" : "ltr",
          }}
        >
          <Grid
            container
            spacing={2}
            alignItems="center"
            justifyContent="center"
          >
            <Grid
              item
              xs={isIpad || isTablet ? 6 : 12}
              md={6}
              order={isMobile ? 2 : 1}
            >
              <Box
                sx={{
                  width: "100%",
                  height: "auto",
                  display: "flex",
                  justifyContent: "center",
                  mt: { xs: 2, md: 0 },
                  marginTop: isMobile ? "20px" : "0px",
                }}
              >
                <img
                  src={isRtl ? download3XAR : download3XEN}
                  alt="Phone displaying app"
                  style={{
                    maxWidth: isMobile
                      ? "200%"
                      : isIpad || isTablet
                      ? "120%"
                      : "152%", // Limit image width to its container
                    marginLeft: isMobile || isIpad || isTablet ? "0" : "20%",
                    objectFit: "contain", // Add object-fit to maintain aspect ratio without cropping
                    marginRight: isMobile
                      ? "0"
                      : isRtl
                      ? "60px"
                      : !showArrow
                      ? "50%"
                      : "0", // Keep height auto to maintain aspect ratio
                  }}
                />
              </Box>
            </Grid>
            {/* {isMobile || !showArrow || isRtl ? null : ( // Do not render the arrow image on mobile
              <Grid item md={1}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                  }}
                >
                  <img
                    src={vector3X}
                    alt="Vector Arrow"
                    style={{
                      maxWidth: "100%",
                      height: "auto",
                      marginLeft: "150px",
                    }}
                  />
                </Box>
              </Grid>
            )} */}
            <Grid item xs={isIpad ? 6 : 12} md={6} order={isMobile ? 1 : 2}>
              <Box
                sx={{
                  textAlign: { xs: "center", md: isRtl ? "right" : "left" },
                  ml: { md: isRtl ? 0 : 10 },
                  mr: { xs: isIpad ? 6 : 1, md: isRtl ? "25%" : 0 },
                }}
              >
                <Typography
                  className={isRtl ? "rtl-text" : ""}
                  variant="h4"
                  sx={{
                    ...textStyle,
                    fontWeight: 700,
                    fontSize: { xs: "16px", md: "18px" },
                    lineHeight: "150%",
                    color: "#6263AB",
                    textAlign: isMobile ? "center" : isRtl ? "right" : "left", // Center text for mobile
                    mb: 2,
                  }}
                >
                  {t("downloadApp")}
                </Typography>
                <Typography
                  className={isRtl ? "rtl-text" : ""}
                  variant="h4"
                  sx={{
                    ...textStyle,
                    fontWeight: 700,
                    fontSize: { xs: "24px", md: "32px" },
                    lineHeight: "130%",
                    color: "#1C1B13",
                    textAlign: isMobile ? "center" : isRtl ? "right" : "left", // Center text for mobile
                    mb: 2,
                  }}
                >
                  {t("readyToFindYourFutureHome")}
                </Typography>
                <Typography
                  className={isRtl ? "rtl-text" : ""}
                  variant="body1"
                  sx={{
                    ...textStyleRegular,
                    fontWeight: 400,
                    fontSize: "18px",
                    lineHeight: "150%",
                    color: "#1C1B13",
                    mb: 2,
                    textAlign: isMobile ? "center" : isRtl ? "right" : "left", // Center text for mobile
                  }}
                >
                  {t("appDescription")}
                </Typography>
                <Typography
                  className={isRtl ? "rtl-text" : ""}
                  sx={{
                    ...textStyleRegular,
                    fontWeight: 400,
                    fontSize: isIpad ? "24px" : "18px",
                    lineHeight: "150%",
                    color: "#1C1B13",
                    textAlign: isMobile ? "center" : isRtl ? "right" : "left", // Center text for mobile

                    mb: 1,
                    mt: 5,
                  }}
                >
                  {t("getTheApp")}
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: {
                      xs: "center",
                      md: isIpad || isTablet ? "center" : "flex-start",
                    },
                    flexDirection: isRtl ? "row-reverse" : "row",
                    textAlign: isRtl ? "right" : "left",
                    gap: 1, // Set gap to 0
                  }}
                >
                  <Box
                    component="a"
                    href="https://app.adjust.com/17q1d5fo"
                    target="_blank"
                  >
                    <img
                      src={googlePlayBadge3X}
                      alt="Get it on Google Play"
                      style={{ maxWidth: "100%", height: "auto" }} // Adjust the image size to fit its container
                    />
                  </Box>
                  <Box
                    component="a"
                    href="https://app.adjust.com/17b556zr"
                    target="_blank"
                  >
                    <img
                      src={appleStoreBadge3X}
                      style={{ maxWidth: "100%", height: "auto" }} // Adjust the image size to fit its container
                      alt="Download on the App Store"
                    />
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </div>
  );
};

export default DownloadAppComponent;
